.Preview_Proposal {
    width: 100%;
    height: 100%;
    color: #ffffff;
    font-family: 'Poppins';
    background-color: #000000 !important;
    position: relative;
}

.Preview_Proposal .div_budget{
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.Preview_Proposal .fixed_budget {
    position: absolute;
    top: 20px;
    right: 20px;
}
.Preview_Proposal .btn_budget{
    cursor: pointer;
    background-color: #ffffff;
    width: 222px;
    color: #2a2c37;
    border-radius: 4px;
    padding: 2px 10px;
    text-align: center;
}

/* position btn fixed */
.Preview_Proposal .show_btn{
    width: 100%;
    height: 60px;
    background-color: #2a2c37;
    position: absolute;
    bottom: 0;
    z-index: 8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.Preview_Proposal .show_btn .qtd_page{
    width: calc(100% - 32px);
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 0px 16px;
}
.Preview_Proposal .show_btn .qtd_page .space_page{
    height: 100%;
    flex-grow: 1;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.Preview_Proposal .show_btn .qtd_page .space_page .color_line{
    height: 6px;
    flex-grow: 1;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.42);
}
.Preview_Proposal .show_btn .qtd_page .space_page .page_active, 
.Preview_Proposal .show_btn .qtd_page .space_page:hover .color_line{
    background-color: #ededed;
}
.Preview_Proposal .show_btn .qtd_page .space_page .img_example{
    width: 310px;
    height: 180px;
    position: absolute;
    z-index: 3;
    top: -196px;
    border: 2px solid #ffffff;
    border-radius: 4px;
    display: none;
    overflow: hidden;
}
.Preview_Proposal .show_btn .qtd_page .space_page .img_example_start {
    left: 0;
}
.Preview_Proposal .show_btn .qtd_page .space_page .img_example_end {
    right: 0;
}
.Preview_Proposal .show_btn .qtd_page .space_page:hover .img_example{
    display: block;
}
.Preview_Proposal .show_btn .opt_alt_page{
    width: 100%;
    height: calc(100% - 24px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.Preview_Proposal .show_btn .opt_alt_page .alt_page{
    font-family: 'Inter';
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 0px 10px;
    width: 130px;
}
.Preview_Proposal .show_btn .opt_alt_page .alt_page .arrow{
    padding: 2px;
}
.Preview_Proposal .show_btn .opt_alt_page .alt_page .arrow:hover{
    background-color: #83858b63;
    border-radius: 4px;
}
.Preview_Proposal .show_btn .opt_alt_page .alt_page .arrow .left{
    transform: rotate(180deg);
}
.Preview_Proposal .show_btn .opt_alt_page .fullscreen{
    padding: 0px 10px;
    width: 130px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.Preview_Proposal .show_btn .opt_alt_page .fullscreen .div_fullscreen{
    padding: 2px;
}
.Preview_Proposal .show_btn .opt_alt_page .fullscreen .div_fullscreen:hover{
    background-color: #83858b63;
    border-radius: 4px;
}

.Preview_Proposal .div_video_or_img{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.Preview_Proposal .div_video_or_img .dotted {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 6;
    
    background-image: -webkit-repeating-radial-gradient(center center, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2) 1px, transparent 1px, transparent 100%);
    -webkit-background-size: 3px 3px;
    -moz-background-size: 3px 3px;
    background-size: 3px 3px;
    background-color: rgb(0 0 0 / 50%);
}
.Preview_Proposal .div_video_or_img .show_full{
    position: relative;
    background-size: cover;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
}
.Preview_Proposal .div_video_or_img .video_background {
    background: #000;
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: 0;
}
.Preview_Proposal .div_video_or_img .video_foreground, .Preview_Proposal .div_video_or_img .video_background iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    scale: 1;
}
@media (min-aspect-ratio: 16/9) {
    .Preview_Proposal .div_video_or_img .video_foreground { 
        height: 300%; 
        top: -100%; 
    }
}
@media (max-aspect-ratio: 16/9) {
    .Preview_Proposal .div_video_or_img .video_foreground { 
        width: 300%; 
        left: -100%; 
    }
}
@media all and (max-width: 600px) {
    .Preview_Proposal .div_video_or_img .vid-info { 
        width: 50%; 
        padding: .5rem; 
    }
    .Preview_Proposal .div_video_or_img .vid-info h1 { 
        margin-bottom: .2rem; 
    }
}
@media only screen and (max-width: 580px) {
    .Preview_Proposal .subtitle{
        width: 100%;
        text-align: center;
    }
}
@media all and (max-width: 500px) {
    .Preview_Proposal .div_video_or_img .vid-info .acronym {
        display: none;
    }
}
@media all and (max-width: 460px) {
    .Preview_Proposal .div_video_or_img .video_foreground, .Preview_Proposal .div_video_or_img .video_background iframe {
        scale: 1.5;
    }
}
/* end */

/* show data page */
.Preview_Proposal .show_data{
    width: 100%;
    max-width: 1224px;
    margin: 0 auto;
    height: calc(100% - 60px);
    position: relative;
}
/* end */

/* show_about_home */
.Preview_Proposal .show_data .show_data_page{
    width: 100%;
    height: 100%;
    display: none;
}
.Preview_Proposal .show_data .show_page_active {
    display: block;
}

.Preview_Proposal .show_about_home{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;
}
.Preview_Proposal .show_about_home .div_text{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 8;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Preview_Proposal .show_about_home .div_text .show_title{
    width: 80%;
    margin: 0 auto;
    font-family: 'Montserrat';
    line-height: 90px;
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 76px;
    margin-bottom: 0;
    text-transform: none;
}
.Preview_Proposal .show_about_home .div_text .show_title_example {
    font-size: 12px;
    line-height: 14px;
}

.Preview_Proposal .show_about_home .show_rede_social{
    position: absolute;
    bottom: 40px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 8;
}
.Preview_Proposal .show_about_home .show_rede_social_example {
    bottom: 8px;
}
.Preview_Proposal .show_about_home .show_rede_social .list_data_rede_social{
    width: 80%;
    margin: 0% auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Preview_Proposal .show_about_home .show_rede_social .list_data_rede_social .name_click{
    font-family: sans-serif;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    line-height: 21px;
    font-weight: 700;
    font-size: 10px;
    border-color: rgba(255, 255, 255, 0.2);
    border-style: solid;
    border-width: 0px 0px 1px
}
.Preview_Proposal .show_about_home .show_rede_social .list_data_rede_social .name_click_example{
    font-size: 6px;
    line-height: 8px;
}
.Preview_Proposal .show_about_home .show_rede_social .list_data_rede_social .div_rede_social{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.Preview_Proposal .show_about_home .show_rede_social .list_data_rede_social .div_rede_social .div_icons_social .icons_rede{
    width: 26px;
    height: auto;
    cursor: pointer;
}
.Preview_Proposal .show_about_home .show_rede_social .list_data_rede_social .div_rede_social .div_icons_social .icons_rede_example{
    width: 16px;
}

@media only screen and (max-width: 950px) {
    .Preview_Proposal .show_about_home .div_text .show_title {
        font-size: 70px;
        line-height: 78px;
    }
}
@media only screen and (max-width: 700px) {
    .Preview_Proposal .show_about_home .div_text .show_title {
        font-size: 52px;
        line-height: 56px;
    }
}
@media only screen and (max-width: 580px) {
    .Preview_Proposal .show_about_home .div_text .show_title {
        width: 90%;
        font-size: 32px;
        line-height: 44px;
        padding-left: 0;
        text-align: center;
    }
    .Preview_Proposal .show_about_home .show_rede_social .list_data_rede_social{
        width: 90%;
    }
}
/* end */

/* show_about_btn */
.Preview_Proposal .show_about_btn{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    cursor: default;
    align-items: center;
    justify-content: center;
}
.Preview_Proposal .show_about_btn .about_btn{
    width: 80%;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Preview_Proposal .show_about_btn .about_btn .div_title{
    width: calc(50% - 30px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    padding: 0px 15px;
}
.Preview_Proposal .show_about_btn .about_btn .div_title_example{
    width: calc(50% - 10px);
    padding: 0px 5px;
    gap: 6px;
}
.Preview_Proposal .show_about_btn .about_btn .div_title_example .subtitle{
    font-size: 3px;
}
.Preview_Proposal .show_about_btn .about_btn .div_title .title{    
    font-size: 76px;
    line-height: 90px;
    font-weight: 700;
    margin-bottom: 20px;
    font-family: 'Montserrat';
}
.Preview_Proposal .show_about_btn .about_btn .div_title .title_example{
    font-size: 19px;
    line-height: 26px;
    margin-bottom: 6px;
}
.Preview_Proposal .show_about_btn .about_btn .div_title .text{
    font-size: 14px;
}
.Preview_Proposal .show_about_btn .about_btn .div_title .text_example{
    font-size: 3px;
}
.Preview_Proposal .show_about_btn .about_btn .div_btn{
    width: calc(50% - 30px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    padding: 0px 15px;
}
.Preview_Proposal .show_about_btn .about_btn .div_btn .show_opt{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}
.Preview_Proposal .show_about_btn .about_btn .div_btn .show_opt .title{
    width: calc(90% - 40px);
    height: 40px;
    background-color: #1b1b1b;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 20px;
    border-radius: 4px;
    cursor: pointer;

    transition: 1s;
}
.Preview_Proposal .show_about_btn .about_btn .div_btn .show_opt .title_example{
    width: calc(90% - 10px);
    height: 10px;
    padding: 0px 5px;
    font-size: 3px;
}
.Preview_Proposal .show_about_btn .about_btn .div_btn .show_opt .title:hover{
    background-color: #434343;
}
.Preview_Proposal .show_about_btn .about_btn .div_btn .show_opt .active{
    background-color: #393737;
}
.Preview_Proposal .show_about_btn .about_btn .div_btn .show_opt .text{
    width: calc(90% - 40px);
    padding-top: 12px;
    text-align: left;
    
    overflow-y: hidden;
    transition: 1s;

    max-height: 0;
    transition: max-height 0.5s ease-out;
}
.Preview_Proposal .show_about_btn .about_btn .div_btn .show_opt .text_example{
    width: calc(90% - 10px);
}
@media only screen and (max-width: 1150px) {
    .Preview_Proposal .show_about_btn .about_btn .div_title .title{
        font-size: 64px;
        line-height: 76px;
    }
}
@media only screen and (max-width: 890px) {
    .Preview_Proposal .show_about_btn .about_btn .div_title .title{
        margin-bottom: 0;
    }
}
@media only screen and (max-width: 850px) {
    .Preview_Proposal .show_about_btn {
        padding: 50px 0px;
    }
    .Preview_Proposal .show_about_btn .about_btn{
        flex-direction: column;
        gap: 40px;
    }
    .Preview_Proposal .show_about_btn .about_btn .div_title{
        width: 100%;
        padding: 0;
    }
    .Preview_Proposal .show_about_btn .about_btn .div_btn{
        width: 100%;
        padding: 0;
    }
    .Preview_Proposal .show_about_btn .about_btn .div_btn .show_opt .title{
        width: calc(100% - 40px);
    }
    .Preview_Proposal .show_about_btn .about_btn .div_btn .show_opt .text{
        width: calc(100% - 40px);
    }
}
@media only screen and (max-width: 700px) {
    .Preview_Proposal .show_about_btn .about_btn .div_title .title{
        font-size: 52px;
        line-height: 56px;
    }
}
@media only screen and (max-width: 580px) {
    .Preview_Proposal .show_about_btn .about_btn{
        width: 90%;
    }
    .Preview_Proposal .show_about_btn .about_btn .div_title .title{
        width: 100%;
        text-align: center;
        font-size: 32px;
        line-height: 44px;
    }
    .Preview_Proposal .show_about_btn .about_btn .div_title .text{
        width: 100%;
        text-align: center;
    }
    .Preview_Proposal .show_about_btn .about_btn .div_btn .show_opt .title{
        justify-content: center;
    }
}
/* end */


/* inf_fixed */
.Preview_Proposal .inf_fixed{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    cursor: default;
}
.Preview_Proposal .inf_fixed .text{
    width: 80%;
    margin: 0 auto;
    font-size: 28px;
    line-height: 39px;
    font-weight: 700;
    margin-bottom: 20px;
    font-family: 'Inter';
    text-align: center;
}
.Preview_Proposal .inf_fixed .text_example {
    font-size: 6px;
    line-height: 8px;
}
/* end */


/* show_about_clients */
.Preview_Proposal .show_about_clients{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    cursor: default;
}
.Preview_Proposal .show_about_clients .about_clients{
    width: 80%;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
}
.Preview_Proposal .show_about_clients .about_clients_example{
    gap: 8px;
}
.Preview_Proposal .show_about_clients .about_clients .title{
    color: #30bacf;
}
.Preview_Proposal .show_about_clients .about_clients_example .title{
    font-size: 10px;
}
.Preview_Proposal .show_about_clients .about_clients .list_clients{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}
.Preview_Proposal .show_about_clients .about_clients .list_clients .logo_client{
    width: 150px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Preview_Proposal .show_about_clients .about_clients_example .list_clients .logo_client{
    width: 40px;
    height: 24px;
}
.Preview_Proposal .show_about_clients .about_clients .list_clients .logo_client .img{
    width: auto;
    height: auto;
}
.Preview_Proposal .show_about_clients .about_clients_example .list_clients .logo_client .img{
    width: 24px;
    height: auto;
}
.Preview_Proposal .show_about_clients .about_clients .show_logo{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    width: 50%;
}
.Preview_Proposal .show_about_clients .about_clients .show_logo .client_logotipo .icons{
    width: 200px;
    height: auto;
}
.Preview_Proposal .show_about_clients .about_clients_example .show_logo .client_logotipo .icons{
    width: 80px;
    height: auto;
}
.Preview_Proposal .show_about_clients .about_clients .show_logo .text_logo{
    color: #30bacf;
}
.Preview_Proposal .show_about_clients .about_clients_example .show_logo .text_logo{
    font-size: 4px;
}
.Preview_Proposal .show_about_clients .about_clients .show_logo .link{
    color: #ffffff;
}
@media only screen and (max-width: 1200px) {
    .Preview_Proposal .show_about_clients .about_clients .show_logo{
        width: 80%;
    }
}
@media only screen and (max-width: 580px) {
    .Preview_Proposal .show_about_clients{
        padding: 50px 0px;
    }
    .Preview_Proposal .show_about_clients .about_clients {
        width: 90%;
        gap: 20px;
    }
    .Preview_Proposal .show_about_clients .about_clients .list_clients{
        justify-content: space-between;
    }
    .Preview_Proposal .show_about_clients .about_clients .show_logo{
        width: 100%;
        flex-direction: column;
    }
    .Preview_Proposal .show_about_clients .about_clients .show_logo .text_logo{
        text-align: center;
    }
}
/* end */



/* show_about_inf */
.Preview_Proposal .show_about_inf{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    cursor: default;
    background-color: #121212;
}
.Preview_Proposal .show_about_inf .about_inf{
    width: calc(50% - 30px);
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    padding: 0px 15px;
}
.Preview_Proposal .show_about_inf .about_inf_example{
    width: 50%;
    padding: 0;
    gap: 6px;
}
.Preview_Proposal .show_about_inf .about_inf .title{
    font-size: 26px;
    line-height: 32px;
    font-weight: 700;
    font-family: 'Montserrat';
}
.Preview_Proposal .show_about_inf .about_inf_example .title{
    font-size: 7px;
    line-height: 9px;
}
.Preview_Proposal .show_about_inf .about_inf .subtitle{
    font-family: 'Inter';
    font-size: 16px;
}
.Preview_Proposal .show_about_inf .about_inf_example .subtitle{
    font-size: 3px;
}
.Preview_Proposal .show_about_inf .about_inf .contact{
    color: #30bacf;
    font-family: 'Inter';
    font-size: 16px;
}
.Preview_Proposal .show_about_inf .about_inf_example .contact{
    font-size: 3px;
}
.Preview_Proposal .show_about_inf .about_inf .list_logotipo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.Preview_Proposal .show_about_inf .about_inf .list_logotipo .show_icon{
    display: flex;
}
.Preview_Proposal .show_about_inf .about_inf .list_logotipo .show_icon .icons{
    width: 40px;
    height: auto;
}
.Preview_Proposal .show_about_inf .about_inf_example .list_logotipo .show_icon .icons{
    width: 12px;
}
@media only screen and (max-width: 850px) {
    .Preview_Proposal .show_about_inf{
        align-items: center;
    }    
    .Preview_Proposal .show_about_inf .about_inf{
        text-align: center;
        width: 90%;
        margin: 0 auto;
        padding: 0px;
    }
    .Preview_Proposal .show_about_inf .about_inf .title{
        text-align: center;
    }
    .Preview_Proposal .show_about_inf .about_inf .subtitle{
        width: 100%;
        text-align: center;
    }
    .Preview_Proposal .show_about_inf .about_inf .contact{
        width: 100%;
        text-align: center;
    }
    .Preview_Proposal .show_about_inf .about_inf .list_logotipo{
        width: 100%;
    }
}
@media only screen and (max-width: 580px) {
    .Preview_Proposal .show_about_inf{
        padding: 50px 0px;
    } 
}
/* end */



/* show_about_star */
.Preview_Proposal .show_about_star{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    cursor: default;
}
.Preview_Proposal .show_about_star .about_star{
    width: 90%;
    height: calc(100% - 92px);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 60px;
}
.Preview_Proposal .show_about_star .about_star_example{
    height: calc(100% - 26px);
    gap: 4px;
}
.Preview_Proposal .show_about_star .about_star .div_title{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
}
.Preview_Proposal .show_about_star .about_star_example .div_title{
    gap: 4px;
}
.Preview_Proposal .show_about_star .about_star_example .div_title .subtitle{
    font-size: 3px;
}
.Preview_Proposal .show_about_star .about_star .div_title .title{ 
    font-size: 62px;
    line-height: 68px;
    font-weight: 700;
    margin-bottom: 20px;
    font-family: 'Montserrat';
}
.Preview_Proposal .show_about_star .about_star_example .div_title .title{ 
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 4px;
}

.Preview_Proposal .show_about_star .about_star .list_assessment{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    overflow-x: auto;
    padding-bottom: 40px;
}
.Preview_Proposal .show_about_star .about_star_example .list_assessment{
    padding-bottom: 6px;
    overflow-x: hidden;
}
.Preview_Proposal .show_about_star .about_star .list_assessment .div_star{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    width: 50%;
    min-width: 50%;
}
.Preview_Proposal .show_about_star .about_star_example .list_assessment .div_star{
    gap: 4px;
}
.Preview_Proposal .show_about_star .about_star .list_assessment .div_star .star{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2px;
}
.Preview_Proposal .show_about_star .about_star_example .list_assessment .div_star .star .icons{
    width: 6px;
    height: 6px;
}
.Preview_Proposal .show_about_star .about_star .list_assessment .div_star .text{
    font-size: 16px;
    font-family: 'inter';
    letter-spacing: 2px;
}
.Preview_Proposal .show_about_star .about_star_example .list_assessment .div_star .text{
    font-size: 4px;
    letter-spacing: 1px;
}
.Preview_Proposal .show_about_star .about_star .list_assessment .div_star .client{
    font-size: 12px;
    text-transform: uppercase;
}

.Preview_Proposal .show_about_star .list_number{
    width: 100%;
    height: 92px;
    background-color: #2b2b2b;
    display: flex;
    flex-direction: row;
}
.Preview_Proposal .show_about_star .list_number_example{
    height: 26px;
}
.Preview_Proposal .show_about_star .list_number .div_number{
    width: 25%;
    height: auto;
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Preview_Proposal .show_about_star .list_number_example .div_number{
    padding: 2px 0px;
}
.Preview_Proposal .show_about_star .list_number .div_number .sigla{
    font-size: 34px;
    font-weight: 600;
}
.Preview_Proposal .show_about_star .list_number_example .div_number .sigla{
    font-size: 6px;
}
.Preview_Proposal .show_about_star .list_number .div_number .number{
    font-size: 34px;
    font-weight: 600;
}
.Preview_Proposal .show_about_star .list_number_example .div_number .number{
    font-size: 6px;
}
.Preview_Proposal .show_about_star .list_number .div_number .title{
    font-size: 14px;
    line-height: 16px;
    padding-left: 10px;
    height: 100%;
    display: flex;
    align-items: center;
}
.Preview_Proposal .show_about_star .list_number_example .div_number .title{
    font-size: 2px;
    line-height: 3px;
    padding-left: 2px;
}
@media only screen and (max-width: 1170px) {
    .Preview_Proposal .show_about_star .list_number .div_number .title{
        font-size: 10px;
        line-height: 12px;
    }
    .Preview_Proposal .show_about_star .list_number .div_number .sigla{
        font-size: 26px;
    }
    .Preview_Proposal .show_about_star .list_number .div_number .number{
        font-size: 26px;
    }
}
@media only screen and (max-width: 1150px) {
    .Preview_Proposal .show_about_star .about_star .div_title .title {
        font-size: 64px;
        line-height: 76px;
    }
}
@media only screen and (max-width: 1060px) {
    .Preview_Proposal .show_about_star .about_star .list_assessment .div_star{
        width: 100%;
        min-width: 100%;
    }
}
@media only screen and (max-width: 940px) {
    .Preview_Proposal .show_about_star .list_number .div_number .title{
        font-size: 8px;
        line-height: 10px;
    }
    .Preview_Proposal .show_about_star .list_number .div_number .sigla{
        font-size: 20px;
    }
    .Preview_Proposal .show_about_star .list_number .div_number .number{
        font-size: 20px;
    }
}
@media only screen and (max-width: 850px) {
    .Preview_Proposal .show_about_star .list_number{
        margin-top: 60px;
        flex-wrap: wrap;
    }
    .Preview_Proposal .show_about_star .list_number .div_number{
        width: 50%;
        text-align: center;
    }
}
@media only screen and (max-width: 700px) {
    .Preview_Proposal .show_about_star .about_star .div_title .title {
        font-size: 52px;
        line-height: 56px;
    }
}
@media only screen and (max-width: 580px) {
    .Preview_Proposal .show_about_star {
        padding-top: 50px;
    }
    .Preview_Proposal .show_about_star .list_number {
        flex-direction: column;
    }
    .Preview_Proposal .show_about_star .list_number .div_number{
        width: 100%;
    }
    .Preview_Proposal .show_about_star .about_star{
        width: 90%;
        gap: 40px;
    }
    .Preview_Proposal .show_about_star .about_star .list_assessment{
        padding-bottom: 10px;
    }
    .Preview_Proposal .show_about_star .about_star .list_assessment .div_star{
        gap: 20px;
    }
    .Preview_Proposal .show_about_star .about_star .list_assessment .div_star .star{
        width: 100%;
        justify-content: center;
    }
    .Preview_Proposal .show_about_star .about_star .list_assessment .div_star .text{
        text-align: center;
    }
    .Preview_Proposal .show_about_star .about_star .list_assessment .div_star .client{
        width: 100%;
        text-align: center;
    }
    .Preview_Proposal .show_about_star .about_star .div_title .title{
        font-size: 32px;
        line-height: 44px;
        margin-bottom: 0;
        width: 100%;
        text-align: center;
    }
    .Preview_Proposal .show_about_star .about_star .div_title .title br{
        display: none;
    }
    .Preview_Proposal .show_about_star .list_number .div_number .sigla{
        font-size: 26px;
    }
    .Preview_Proposal .show_about_star .list_number .div_number .number{
        font-size: 26px;
    }
    .Preview_Proposal .show_about_star .list_number .div_number .title{
        font-size: 14px;
        line-height: 16px;
        text-align: left;
    }
}
/* end */


/* proposal_list_services */
.Preview_Proposal .proposal_list_services{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    cursor: default;
}
.Preview_Proposal .proposal_list_services .list_services{
    width: 90%;
    margin: 0 auto;
    text-align: left;
}
.Preview_Proposal .proposal_list_services .list_services .div_list_category{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 40px;
    gap: 30px;
}
.Preview_Proposal .proposal_list_services .list_services_example .div_list_category{
    margin-top: 10px;
    gap: 4px;
}
.Preview_Proposal .proposal_list_services .list_services .div_list_category .div_category{
    width: 50%;
}
.Preview_Proposal .proposal_list_services .list_services .div_list_category .div_category .bg_color{
    width: 80px;
    height: 16px;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: #ffffff;
}
.Preview_Proposal .proposal_list_services .list_services_example .div_list_category .div_category .bg_color{
    width: 26px;
    height: 4px;
    margin-bottom: 4px;
}
.Preview_Proposal .proposal_list_services .list_services .div_list_category .div_category .name_category{
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    font-family: 'Montserrat';
    text-transform: uppercase;
    letter-spacing: 1px;
}
.Preview_Proposal .proposal_list_services .list_services_example .div_list_category .div_category .name_category{
    font-size: 10px;
    line-height: 12px;
}
.Preview_Proposal .proposal_list_services .list_services .div_list_category .div_category .list_service{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    margin-top: 30px;
}
.Preview_Proposal .proposal_list_services .list_services_example .subtitle {
    font-size: 3px;
}
.Preview_Proposal .proposal_list_services .list_services_example .div_list_category .div_category .list_service{
    font-size: 2px;
    margin-top: 12px;
}
/* end */



/* div_name_category */
.Preview_Proposal .div_name_category{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    cursor: default;
}
.Preview_Proposal .div_name_category .div_data_category{
    width: 80%;
    margin: 0 auto;
    text-align: left;
    z-index: 8;
}
.Preview_Proposal .div_name_category .div_data_category .bg_color{
    width: 140px;
    height: 30px;
    border-radius: 30px;
}
.Preview_Proposal .div_name_category_example .div_data_category .bg_color{
    width: 36px;
    height: 10px;
}
.Preview_Proposal .div_name_category .div_data_category .name_category{
    font-family: 'Inter';
    font-size: 64px;
    font-weight: 900;
    letter-spacing: 2px;
}
.Preview_Proposal .div_name_category_example .div_data_category .name_category{
    font-size: 14px;
}

.Preview_Proposal .div_list_service{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    position: relative;
    overflow: hidden;
    cursor: default;
}
.Preview_Proposal .div_list_service .div_line{
    width: 6%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.Preview_Proposal .div_list_service .div_line .div_show_logo{
    position: absolute;
    top: 150px;
    padding: 24px 0px;
}
.Preview_Proposal .div_list_service_example .div_line .div_show_logo{
    top: 30px;
    padding: 4px 0px;
}
.Preview_Proposal .div_list_service .div_line .div_show_logo .icons{
    width: 40px;
    height: auto;
}
.Preview_Proposal .div_list_service_example .div_line .div_show_logo .icons{
    width: 10px;
}
.Preview_Proposal .div_list_service .div_line .line_color{
    height: 100%;
    border-right: 1px solid #5d5d5d;
}
.Preview_Proposal .div_list_service .div_data{
    width: calc(44% - 30px);
    height: 100%;
    padding: 0px 15px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}
.Preview_Proposal .div_list_service_example .div_data{
    width: calc(44% - 10px);
    padding: 0px 5px;
}
.Preview_Proposal .div_list_service .div_data_full{
    width: calc(94% - 30px);
}
.Preview_Proposal .div_list_service_example .div_data_full{
    width: calc(94% - 10px);
    padding: 0px 5px;
}
.Preview_Proposal .div_list_service .div_data .sigla{
    font-size: 16px;
}
.Preview_Proposal .div_list_service_example .div_data .sigla{
    font-size: 6px;
}
.Preview_Proposal .div_list_service .div_data .name_service{
    font-family: 'Inter';
    font-weight: 900;
    letter-spacing: 2px;
    font-size: 32px;
    line-height: 32px;
    text-transform: uppercase;
}
.Preview_Proposal .div_list_service_example .div_data .name_service{
    font-size: 6px;
    line-height: 6px;
    letter-spacing: 1px;
}
.Preview_Proposal .div_list_service .div_data .text{
    font-family: 'Poppins';
    letter-spacing: 2px;
    font-size: 14px;
    line-height: 16px;
    margin-top: 30px;
}
.Preview_Proposal .div_list_service_example .div_data .text{
    letter-spacing: 0px;
    font-size: 3px;
    line-height: 4px;
    margin-top: 6px;
}
.Preview_Proposal .div_list_service .div_data .list_portfolio{
    margin-top: 40px;
    border: 1px solid #ffffff;
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
}
.Preview_Proposal .div_list_service_example .div_data .list_portfolio{
    margin-top: 6px;
    padding: 4px 6px;
    font-size: 3px;
    border-radius: 2px;
}
.Preview_Proposal .div_list_service .show_div_file{
    width: 50%;
    height: 100%;
}
.Preview_Proposal .div_list_service .show_div_file .div_file{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}
.Preview_Proposal .div_list_service .show_div_file .div_file_two{
    height: 50%;
}
.Preview_Proposal .div_list_service .show_div_file .div_file .show_data{
    width: 100%;
    height: 100%;
}
.Preview_Proposal .div_list_service .show_div_file .div_file .show_data .div_img{
    width: 100%;
    height: 100%;
    display: flex;
}

.Preview_Proposal .div_list_service .show_div_file .div_data_page_type{
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}
.Preview_Proposal .div_list_service .show_div_file .div_data_page_type .div_img{
    width: 100%;
    height: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.Preview_Proposal .div_list_service .show_div_file .type_2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Preview_Proposal .div_list_service .show_div_file .show_type_2{
    height: 50%;
    width: 100%;
    position: relative;
}
.Preview_Proposal .div_list_service .show_div_file .show_type_6{
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
}
.Preview_Proposal .div_list_service .show_div_file .show_type_6 .div_img{
    width: auto;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.Preview_Proposal .div_list_service .show_div_file .type_3{
    display: flex;
    flex-direction: row;
}
.Preview_Proposal .div_list_service .show_div_file .type_6{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.Preview_Proposal .div_list_service .show_div_file .type_7{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* end */