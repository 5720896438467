
.Preview_Portfolio{
    width: 100%;
    height: 100%;
    color: #ffffff;
    font-family: 'Poppins';
    background-color: #000000;
}
.PopUp .title_return {
    color: #6c757d;
}

.Preview_Portfolio .container{
    width: 80%;
    margin: 0% auto;
    min-height: 160px;
}


/* line fixed */
.Preview_Portfolio .line_fixed{
    position: fixed;
    z-index: 10;
    transition: 1s;
}
.Preview_Portfolio .line_fixed .line_{
    width: 0px;
    height: 100%;
    border-left: 1px solid rgba(77, 77, 77, .3);
    position: fixed;
    z-index: 10;
    pointer-events: none;
}
.Preview_Portfolio .line_fixed .line_1 {
    left: 25%;
}
.Preview_Portfolio .line_fixed .line_2 {
    left: 50%;
}
.Preview_Portfolio .line_fixed .line_3 {
    left: 75%;
}
@media only screen and (max-width: 850px) {
    .Preview_Portfolio .line_fixed .line_1 {
        left: 50%;
    }
    .Preview_Portfolio .line_fixed .line_2, .Preview_Portfolio .line_fixed .line_3 {
        display: none;
    }
}
/* end */


/* select client */
.Preview_Portfolio .div_topic_fixed{
    z-index: 10;
    position: fixed;
    left: 10%;
    min-height: auto;
}
.Preview_Portfolio .div_topic_fixed .logotipo{
    padding-top: 50px;
}
.Preview_Portfolio .div_topic_fixed .logotipo .logotipo_img{
    width: auto;
    height: 100%;
}
.Preview_Portfolio .selected{
    position: absolute;
    right: 0;
    top: 50px;
    z-index: 4;
}
.Preview_Portfolio .selected .opt_selected{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(7, 196, 214, 0.5);
    border-radius: 30px;
}
.Preview_Portfolio .selected .opt_selected .icons{
    width: 18px;
    height: 18px;
}

.Preview_Portfolio .selected .list_proposal{
    position: absolute;
    right: 0;
    background-color: rgba(7, 196, 214, 0.5);
    color: #121212;
    margin-top: 4px;
    width: 250px;
    z-index: 20;
    max-height: 152px;
    overflow-y: auto;
}
.Preview_Portfolio .selected .list_proposal .name_opt{
    padding: 10px;
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.Preview_Portfolio .selected .list_proposal .name_opt:hover{
    background-color: rgba(7, 196, 214);
}
.Preview_Portfolio .selected .list_proposal .name_opt .input_checkbox{
    width: 18px;
    height: 18px;
    cursor: pointer;
}
.Preview_Portfolio .selected .list_proposal .div_save{
    padding: 10px;
    cursor: pointer;
    color: #ffffff;
    text-align: center;
    background-color: #2F4858;
    border-radius: 0px 0px 6px 6px;
}
/* end */


/* div initial file */
.Preview_Portfolio .div_initial{
    width: 100%;
    height: 90%;
    position: relative;
    display: flex;
    transition: 1s;
}
.Preview_Portfolio .div_initial .color_{
    width: 100%;
    height: 90vh;
    position: absolute;
    z-index: 10;
}
.Preview_Portfolio .div_initial .logotipo{
    position: absolute;
    left: 20%;
    top: 6%;
    z-index: 4;
}
.Preview_Portfolio .div_initial .img_full{
    top: 0px;
    background-size: cover;
    width: 100%;
    height: 90vh;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: -999;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    z-index: 1;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.Preview_Portfolio .div_initial .div_mask{
    position: absolute;
    bottom: 0px;
    left: 0;
    display: flex;
    align-items: flex-end;
    width: -webkit-fill-available;
    z-index: 3;
}
.Preview_Portfolio .div_initial .div_mask .mask_1{
    flex-grow: 1;
    height: 150px;
}
.Preview_Portfolio .div_initial .div_mask .mask_2{
    flex-grow: 1;
    height: 100px;
}
.Preview_Portfolio .div_initial .div_mask .mask_3{
    flex-grow: 1;
    height: 70px;
}
.Preview_Portfolio .div_initial .div_mask .mask_4{
    flex-grow: 1;
    height: 180px;
}
/* end */


/* div line color 25% */
.Preview_Portfolio .div_initial .div_line{
    width: 100%;
    height: 22%;
    position: absolute;
    bottom: 0;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}
.Preview_Portfolio .div_initial .div_line .line{
    width: 25%;
    background-color: #121212;
}
.Preview_Portfolio .div_initial .div_line .bg_1{
    height: 78%;
}
.Preview_Portfolio .div_initial .div_line .bg_2{
    height: 32%;
}
.Preview_Portfolio .div_initial .div_line .bg_3{
    height: 10%;
}
.Preview_Portfolio .div_initial .div_line .bg_4{
    height: 100%;
}
@media only screen and (max-width: 850px) {
    .Preview_Portfolio .div_initial .div_line .bg_1, .Preview_Portfolio .div_initial .div_line .bg_2{
        width: 50%;
    }
    .Preview_Portfolio .div_initial .div_line .bg_3, .Preview_Portfolio .div_initial .div_line .bg_4{
        display: none;
    }
}
@media only screen and (max-width: 580px) {
    .Preview_Portfolio .div_initial .div_line{
        bottom: -2px;
    }
    .Preview_Portfolio .div_initial .div_line .bg_1{
        height: 100%;
    }
    .Preview_Portfolio .div_initial .div_line .bg_2{
        height: 50%;
    }
}
/* end */


/* div_cover_text */
.Preview_Portfolio .div_initial .div_cover_text {
    width: 50%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    color: #ededed;
    padding-left: 20%;
    transition: 0.5s;
}
.Preview_Portfolio .div_initial .div_cover_text .data_project{
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: 0.5s;
}
.Preview_Portfolio .div_initial .div_cover_text .data_project .name_cliente{
    color: #ededed;
    font-size: 13px;
    text-transform: uppercase;
    margin: 8px 0px;
    letter-spacing: 2px;
    font-weight: 500;
    transition: 0.5s;
}
.Preview_Portfolio .div_initial .div_cover_text .data_project .name_cliente .dest_a{
    opacity: .5;
}
.Preview_Portfolio .div_initial .div_cover_text .data_project .name_cliente .dest_b{
    opacity: 1;
}
.Preview_Portfolio .div_initial .div_cover_text .data_project .title{
    font-size: 100px;
    font-weight: 600;
    line-height: 110px;
    text-align: start;
}
.Preview_Portfolio .div_initial .div_cover_text .data_project .subtitle{
    font-weight: 200;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 18px;
    margin-left: 2px;
}
@media only screen and (max-width: 850px) {
    .Preview_Portfolio .div_initial .div_cover_text{
        width: 90%;
        margin: 0 auto;
        align-items: center;
        padding-left: 0;
    }
    .Preview_Portfolio .div_initial .div_cover_text .data_project{
        flex-direction: row;
        gap: 40px;
    }
    .Preview_Portfolio .div_initial .div_cover_text .data_project .name_cliente{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .Preview_Portfolio .div_initial .div_cover_text .data_project .title{
        font-size: 78px;
        line-height: 78px;
        text-align: center;
    }
}
@media only screen and (max-width: 560px) {
    .Preview_Portfolio .div_initial .div_cover_text .data_project .title{
        font-size: 52px;
        line-height: 52px;
    }
}
/* end */


/* div_cover_file */
.Preview_Portfolio .div_cover_file {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    color: #ededed;
    transition: 0.5s;
    padding: 60px 0px;
}
.Preview_Portfolio .div_cover_file .div_data_project {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    color: #ededed;
    transition: 0.5s;
}
.Preview_Portfolio .div_cover_file .title_only .data_project .title{
    font-size: 72px;
    font-weight: 600;
    line-height: 72px;
    text-align: end;
}
.Preview_Portfolio .div_cover_file .title_only .data_project {
    width: calc(40% - 25px);
    padding-left: 10%;
    padding-right: 25px;
}

.Preview_Portfolio .div_cover_file .show_data_text {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 50px;
}
.Preview_Portfolio .div_cover_file .show_data_text .div_data_project{
    width: 40%;
    align-items: flex-end;
}
.Preview_Portfolio .div_cover_file .show_data_text .div_data_project .data_project{
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transition: 0.5s;
}
.Preview_Portfolio .div_cover_file .show_data_text .div_data_project .data_project .name_cliente{
    color: #ededed;
    font-size: 13px;
    text-transform: uppercase;
    margin: 8px 0px;
    letter-spacing: 2px;
    font-weight: 500;
    transition: 0.5s;
}
.Preview_Portfolio .div_cover_file .show_data_text .div_data_project .data_project .name_cliente .dest_a{
    opacity: .5;
}
.Preview_Portfolio .div_cover_file .show_data_text .div_data_project .data_project .name_cliente .dest_b{
    opacity: 1;
}
.Preview_Portfolio .div_cover_file .show_data_text .div_data_project .data_project .subtitle{
    font-weight: 200;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 18px;
    margin-left: 2px;
}

.Preview_Portfolio .div_cover_file .show_data_text .div_data_text {
    width: 40%;
    height: auto;
}
.Preview_Portfolio .div_cover_file .show_data_text .div_data_text .div_text{
    text-align: left;
    font-size: 16px;
    line-height: 26px;
    font-family: 'inter';
}
@media only screen and (max-width: 1100px) {
    .Preview_Portfolio .div_cover_file .title_only .data_project{
        width: calc(50% - 25px);
        padding-left: 0;
    }
}
@media only screen and (max-width: 850px) {
    .Preview_Portfolio .div_cover_file .title_only .data_project{
        width: calc(100% - 30px);
        padding: 0px 15px;
    }
    .Preview_Portfolio .div_cover_file .title_only .data_project .title{
        text-align: center;
    }
    .Preview_Portfolio .div_cover_file .show_data_text{
        flex-direction: column;
        gap: 20px;
    }
    .Preview_Portfolio .div_cover_file .show_data_text .div_data_project{
        width: calc(100% - 30px);
        padding: 0px 15px;
        align-items: center;
    }
    .Preview_Portfolio .div_cover_file .show_data_text .div_data_project .data_project{
        flex-direction: row;
        gap: 50px;
        width: 100%;
    }
    .Preview_Portfolio .div_cover_file .show_data_text .div_data_project .data_project .name_cliente{
        width: 50%;
        text-align: right;
    }
    .Preview_Portfolio .div_cover_file .show_data_text .div_data_project .data_project .name_cliente:nth-child(n+2){
        text-align: left;
    }
    .Preview_Portfolio .div_cover_file .show_data_text .div_data_text{
        width: 90%;
        padding: 0px 15px;
        margin: 0 auto;
    }
    .Preview_Portfolio .div_cover_file .show_data_text .div_data_text .div_text{
        text-align: center;
    }
}
@media only screen and (max-width: 560px) {
    .Preview_Portfolio .div_cover_file{
        padding: 40px 0px;
    }
    .Preview_Portfolio .div_cover_file .title_only .data_project .title{
        font-size: 54px;
        line-height: 54px;
    }
    .Preview_Portfolio .div_cover_file .show_data_text .div_data_text{
        width: calc(100% - 30px);
    }
}
/* end */


/* show_datasheet */
.Preview_Portfolio .show_datasheet{
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 50px;
    padding: 60px 0px;
}
.Preview_Portfolio .show_datasheet .text{
    width: 40%;
    text-align: left;
    font-size: 16px;
    line-height: 26px;
    font-family: 'inter'; 
}
@media only screen and (max-width: 850px) {
    .Preview_Portfolio .show_datasheet {
        width: calc(80% - 30px);
        margin: 0 auto;
    }
    .Preview_Portfolio .show_datasheet .text:nth-child(n+1){
        display: none;
    }
    .Preview_Portfolio .show_datasheet .text:nth-child(n+2){
        width: auto;
        display: block;
        text-align: center;
    }
}
@media only screen and (max-width: 560px) {
    .Preview_Portfolio .show_datasheet{
        width: 100%;
    }
    .Preview_Portfolio .show_datasheet .text:nth-child(n+2){
        width: calc(100% - 30px);
        padding: 0px 15px;
    }
}
/* end */


/* div_contents */
.Preview_Portfolio .div_contents{
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 60px 0px;
}
.Preview_Portfolio .contents_height_top{
    justify-content: flex-start;
}
.Preview_Portfolio .contents_height_center{
    justify-content: center;
}
.Preview_Portfolio .contents_height_bottom{
    justify-content: flex-end;
}
.Preview_Portfolio .contents_width_left{
    align-items: flex-start;
}
.Preview_Portfolio .contents_width_center{
    align-items: center;
}
.Preview_Portfolio .contents_width_right{
    align-items: flex-end;
}
.Preview_Portfolio .space_gap_2{
    gap: 50px;
}
.Preview_Portfolio .space_gap_3{
    gap: 40px;
}
.Preview_Portfolio .space_gap_4{
    gap: 30px;
}
.Preview_Portfolio .space_gap_5{
    gap: 20px;
}
.Preview_Portfolio .space_gap_6{
    gap: 10px;
}
.Preview_Portfolio .div_contents .div_show_contents{
    width: 100%;
    height: 100%;
    display: flex;
}
.Preview_Portfolio .div_contents .show_data_contents{
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.Preview_Portfolio .div_contents .height_top{
    justify-content: flex-start;
}
.Preview_Portfolio .div_contents .height_center{
    justify-content: center;
}
.Preview_Portfolio .div_contents .height_bottom{
    justify-content: flex-end;
}
.Preview_Portfolio .div_contents .width_data_1{
    width: 100%;
}
.Preview_Portfolio .div_contents .width_data_2{
    width: 50%;
}
.Preview_Portfolio .div_contents .width_data_3{
    width: 33.3333%;
}
.Preview_Portfolio .div_contents .width_data_4{
    width: 25%;
}
.Preview_Portfolio .div_contents .width_data_5{
    width: 20%;
}
.Preview_Portfolio .div_contents .width_data_6{
    width: 16.666%;
}
@media only screen and (max-width: 850px) {
    .Preview_Portfolio .div_contents .div_show_contents{
        flex-direction: column;
    }
    .Preview_Portfolio .div_contents .width_data_2, .Preview_Portfolio .div_contents .width_data_3, .Preview_Portfolio .div_contents .width_data_4, .Preview_Portfolio .div_contents .width_data_5, .Preview_Portfolio .div_contents .width_data_6{
        width: 100%;
    }
    .Preview_Portfolio .space_gap_1, .Preview_Portfolio .space_gap_2, .Preview_Portfolio .space_gap_3, .Preview_Portfolio .space_gap_4, .Preview_Portfolio .space_gap_5, .Preview_Portfolio .space_gap_6{

    }
}
/* end */