.Page_Confirmation .list_type_service {
    border-top: 2px solid #ebebeb;
    padding-top: 6px;
    margin-top: 14px;
}
.Page_Confirmation .show_page_data .list_inf_data{
    border: none;
    border-bottom: 1px solid #E2E8F0;
    margin-bottom: 10px;
    border-radius: 0;
    padding-bottom: 20px;
}
.Page_Confirmation .show_page_data .list_inf_data:nth-last-child(-n + 1){
    border: none;
    padding-bottom: 0px;
}