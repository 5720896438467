.Page_About .list_type_service {
    border-top: 2px solid #ebebeb;
    padding-top: 6px;
    margin-top: 14px;
}
.Page_About .star{
    width: 60px;
    text-align: center;
}
.Page_About .obs{
    text-align: right;
}
.Page_About .obs .caract{
    font-size: 30px;
    line-height: 10px;
    position: relative;
    top: 4px;
    margin-left: 10px;
}
.Page_About .obs .caract:nth-last-child(-n+1){
    margin-left: 0;
    margin-right: 4px;
}