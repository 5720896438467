.PopUp .historic_budget{
    width: 800px;
}
.PopUp .historic_budget table{
    border: 1px solid #E2E8F0;
    border-radius: 10px;
    padding-bottom: 0;
}
.PopUp .historic_budget .reactivate {
    color: #ffffff;
    padding: 6px;
    border-radius: 6px;
    background-color: #7c9f06;
    cursor: pointer;
    width: 120px;
    text-align: center;
}
.PopUp .historic_budget .version_actual{
    color: #ffffff;
    padding: 6px;
    border-radius: 6px;
    background-color: #E51D4D;
    cursor: pointer;
    width: 120px;
    text-align: center;
}