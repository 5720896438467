.Page_Budget_Details .select_signature {
    width: 200px;
}
.Page_Budget_Details .space_data {
    min-width: 230px;
    max-width: 246px;
}
.Page_Budget_Details .space_contact{
    width: 400px;
}
.Page_Budget_Details .select_client {
    width: 300px;
}
.Page_Budget_Details .date_space{
    width: 150px !important;
}
.Page_Budget_Details .cash {
    width: 80px;
    text-align: center;
}
.Page_Budget_Details .type_remuve_text{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}
.Page_Budget_Details .text_no_text{
    border: 2px solid #ebebeb;
    border-radius: 6px;
    padding: 10px;
}
.Page_Budget_Details .type_lock_close{
    display: flex;
    align-items: center;
    gap: 8px;
}
.Page_Budget_Details .type_lock_close .icons{
    width: 16px;
    height: 16px;
    display: block;
}
.Page_Budget_Details .status {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 600;
    width: 182px;
    padding: 0px 14px;
    gap: 10px;
    position: relative;
    cursor: pointer;
    height: 36px;
    min-height: 36px;
}
.Page_Budget_Details .opt_status{
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 20;
    width: 210px;
    margin-top: 4px;
    background-color: #FFFFFF;
    transition: opacity 247ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 165ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(255, 255, 255);
    color: rgb(17, 24, 39);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
    overflow: hidden auto;
    border-radius: 6px;
    cursor: pointer;
    top: 40px;
}
.Page_Budget_Details .close_list_text{
    display: none;
}
.Page_Budget_Details .sub_{
    border-radius: 0;
    color: #6a85ae;
}
.Page_Budget_Details .sub_:hover{
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
}
.Page_Budget_Details .icon_status{
    display: block;
    width: 16px;
    height: 16px;
}
.Page_Budget_Details .tax{
    width: 50px;
    text-align: center;
}
.Page_Budget_Details .div_cash_tax{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.Page_Budget_Details .list_services{
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 60px;
    justify-content: center;
    width: 100%;
    border: 2px dashed #6a85ae;
    border-radius: 8px;
}
.Page_Budget_Details .list_services .new_add_bloco{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #F00000;
    text-transform: uppercase;
    cursor: pointer;
}
.Page_Budget_Details .data_services{
    width: -webkit-fill-available;
}
.Page_Budget_Details .data_services:nth-child(n+2) {
    border-top: 1px solid #c1c1c1;
    margin-top: 16px;
    padding-top: 10px;
}
.Page_Budget_Details .data_services .list_type_data{
    margin-top: 16px;
}
.Page_Budget_Details .type_discount{
    width: 210px;
}
.Page_Budget_Details .discount{
    width: 100px;
    text-align: center;
}

.Page_Budget_Details .return_copy_link{
    color: #989a9d;
    background-color: #a9eb9352;
    padding: 4px 10px;
    border-radius: 6px;
}

.Page_Budget_Details .clear_text{
    cursor: pointer;
}
.Page_Budget_Details .show_cash_total{
    font-family: 'inter';
    font-weight: 700;
    padding-left: 6px;
}
.Page_Budget_Details .select_status{
    width: 260px;
}

.Page_Budget_Details .topic_fixed{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.Page_Budget_Details .topic_fixed .opt_click{
    width: 170px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    background-color: #037f4b;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
}
.Page_Budget_Details .topic_fixed .opt_click .qtd_tasks{
    position: absolute;
    right: -10px;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #f00000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.Page_Budget_Details .chance_close, 
.Page_Budget_Details .type_budget{
    width: 154px;
}
.Page_Budget_Details .not_edit{
    cursor: default;
    color: #a1a1a1;
}
.Page_Budget_Details .text_{
    border: 2px solid #ebebeb;
    border-radius: 6px;
    padding: 10px;
}
.Page_Budget_Details .customer_since{
    width: 120px;
    text-align: center;
}
.Page_Budget_Details .div_show_category{
    width: fit-content !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}
.show_page_data .div_show_category .list_input_data{
    border: 1px solid #E2E8F0;
    border-radius: 6px;
    padding-bottom: 16px;
    margin-bottom: 20px;
    padding: 10px;
}
.Page_Budget_Details .service_code{
    width: 150px;
    text-align: center;
}