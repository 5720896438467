.Preview_IntegrationManual {
    width: 100%;
    height: 100%;
    color: #6c757d;
    font-family: 'Archivo';
    background-color: #434343;
}

.Preview_IntegrationManual .show_data {
    width: 100%;
    max-width: 1224px;
    margin: 0 auto;
    height: calc(100% - 70px);
    position: relative;
}
.Preview_IntegrationManual .show_data .div_line{
    position: fixed;
    z-index: 2;
    transition: 1s;
}
.Preview_IntegrationManual .show_data .div_line .line_{    
    width: 0px;
    height: 100%;
    z-index: 10;
    pointer-events: none;
    position: fixed;
    border-left: 1px solid rgba(77, 77, 77, .3);
}
.Preview_IntegrationManual .show_data .div_line .line_0{
    left: 25%;
}
.Preview_IntegrationManual .show_data .div_line .line_1{
    left: 50%;
}
.Preview_IntegrationManual .show_data .div_line .line_2{
    left: 75%;
}
.Preview_IntegrationManual .show_data .show_data_page{
    width: 100%;
    height: 100%;
    display: none;
}
.Preview_IntegrationManual .show_data .show_page_active{
    display: block;
}

.Preview_IntegrationManual .show_btn{
    width: 100%;
    height: 60px;
    background-color: #2a2c37;
    position: absolute;
    bottom: 0;
    z-index: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.Preview_IntegrationManual .show_btn .opt_alt_page{
    width: 100%;
    height: calc(100% - 24px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.Preview_IntegrationManual .show_btn .qtd_page{
    width: calc(100% - 32px);
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 0px 16px;
}
.Preview_IntegrationManual .show_btn .qtd_page .space_page{
    height: 100%;
    flex-grow: 1;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.Preview_IntegrationManual .show_btn .qtd_page .space_page .color_line{
    height: 6px;
    flex-grow: 1;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.42);
}
.Preview_IntegrationManual .show_btn .qtd_page .space_page .page_active,
.Preview_IntegrationManual .show_btn .qtd_page .space_page:hover .color_line{
    background-color: #ededed;
}
.Preview_IntegrationManual .show_btn .qtd_page .space_page .img_example{
    width: 310px;
    height: 180px;
    position: absolute;
    z-index: 3;
    top: -196px;
    border: 2px solid #ffffff;
    border-radius: 4px;
    display: none;
    overflow: hidden;
}
.Preview_IntegrationManual .show_btn .qtd_page .space_page .img_example_start{
    left: 0;
}
.Preview_IntegrationManual .show_btn .qtd_page .space_page .img_example_end{
    right: 0;
}

.Preview_IntegrationManual .show_btn .opt_alt_page .alt_page{
    font-family: 'Inter';
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0px 10px;
}
.Preview_IntegrationManual .show_btn .opt_alt_page .alt_page .arrow{
    padding: 2px;
}
.Preview_IntegrationManual .show_btn .opt_alt_page .alt_page .arrow:hover{
    background-color: #83858b63;
    border-radius: 4px;
}
.Preview_IntegrationManual .show_btn .opt_alt_page .alt_page .arrow .left{
    transform: rotate(180deg);
}

.Preview_IntegrationManual .show_btn .opt_alt_page .fullscreen{
    padding: 0px 10px;
}
.Preview_IntegrationManual .show_btn .opt_alt_page .fullscreen .div_fullscreen{
    padding: 2px;
}
.Preview_IntegrationManual .show_btn .opt_alt_page .fullscreen .div_fullscreen:hover{
    background-color: #83858b63;
    border-radius: 4px;
}