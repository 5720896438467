.PopUp .popup_text_fixed{
    width: 500px;
}
.PopUp .popup_text_fixed .list_input_data {
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.PopUp .popup_text_fixed .div_input{
    width: auto;
}
.PopUp .popup_text_fixed .button_text_fixed{
    width: -webkit-fill-available;
}