.div_list_show_video {
    width: 100%;
    position: relative;
}
.div_list_show_video .div_show_video{
    transition: 1s;
}
.div_list_show_video .div_show_video .img_video{
    display: flex;
}
.div_list_show_video .div_show_video .img_hide, .div_list_show_video .div_show_video .video_hide{
    display: none;
}
.div_list_show_video .div_show_video .video_show{
    opacity: 1;
    z-index: 1;
}
.div_list_show_video .div_show_video .div_title_play{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 2;
    background-color: rgb(0 0 0 / 50%);
    transition: 1s;
    cursor: pointer;
    font-size: 32px;
    font-family: unset;
}
.div_list_show_video .div_show_video .div_title_drive{
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
    font-size: 32px;
    font-family: unset;
}
.div_list_show_video .div_show_video .play_hide{
    transition: 1s;
    animation: play_hide 1s ease-in-out;
    animation-fill-mode: forwards;
}
@keyframes play_hide {
    0%{
        height: 100%;
        opacity: 1;
    }
    100% {
        height: calc(100% - 50px);
        opacity: 0;
    }
}
.div_list_show_video .div_show_video .icons_play{
    width: 150px;
}
.div_list_show_video .div_show_video .div_video iframe{
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 580px) {
    .div_list_show_video{
        box-sizing: border-box !important;
    }
}