.Page_Clients {
    width: 100%;
    height: auto;
}

.Page_Clients .list_input_data{
    align-items: center;
    justify-content: center;
}
.Page_Clients .list_input_data .year{
    width: 114px;
}
.Page_Clients .list_input_data .budget{
    width: 200px;
}
.Page_Clients .list_input_data .new_data_page, .Page_Clients .list_input_data .new_block_text{
    padding: 10px 14px;
    text-transform: lowercase;
    font-size: 14px;
}