.Page_Proposal_Details{
    width: 100%;
    height: auto;
}
.Page_Proposal_Details .list_portfolio_new_proposal{
    flex-wrap: wrap;
}
.Page_Proposal_Details .div_add_link{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.Page_Proposal_Details .new_link{
    background-color: #E51D4D;
    border-radius: 20px;
    padding: 4px;
    cursor: pointer;
}
.Page_Proposal_Details .subtitle{
    color: #CDD1D6;
    font-weight: 500;
}
.Page_Proposal_Details .show_opt_config{
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.Page_Proposal_Details .title_fixed{
    color: #606875;
    font-weight: 500;
}
.Page_Proposal_Details .space_top_fixed{
    margin-top: 30px;
}
.Page_Proposal_Details .show_status{
    flex-direction: column;
}
.Page_Proposal_Details .list_opt_status{
    padding: 6px 10px;
    color: #ffffff;
    width: -webkit-fill-available;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;
}
.Page_Proposal_Details .show_list_opt_status{
    margin-top: 10px;
    border-radius: 10px;
    padding: 10px;
    color: #ffffff;
    background-color: #eef1f5;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    gap: 6px;
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    z-index: 3;
    top: 30px;
}
.Page_Proposal_Details .status_1{
    cursor: pointer;
    padding: 6px 10px;
    background-color: #c4c4c6;
}
.Page_Proposal_Details .status_2{
    cursor: pointer;
    padding: 6px 10px;
    background-color: #0484c1;
}
.Page_Proposal_Details .status_3{
    cursor: pointer;
    padding: 6px 10px;
    background-color: #037f4b;
}
.Page_Proposal_Details .status_4{
    cursor: pointer;
    padding: 6px 10px;
    background-color: #7a4bcf;
}
.Page_Proposal_Details .status_5{
    cursor: pointer;
    padding: 6px 10px;
    background-color: #01c875;
}
.Page_Proposal_Details .status_6{
    cursor: pointer;
    padding: 6px 10px;
    background-color: #e3445a;
}
.Page_Proposal_Details .status_7{
    cursor: pointer;
    padding: 6px 10px;
    background-color: #ffca00;
}
.Page_Proposal_Details .status_8{
    cursor: pointer;
    padding: 6px 10px;
    background-color: #65ccff;
}
.Page_Proposal_Details .space_client{
    width: 392px;
}
.Page_Proposal_Details .list_contact{
    border-bottom: 1px solid #E2E8F0;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.Page_Proposal_Details .list_opt_new_contact{
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 10px;
}
.Page_Proposal_Details .show_opt_contact{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}
.Page_Proposal_Details .show_width_contact{
    width: -webkit-fill-available;
}

.Page_Proposal_Details .list_about{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 10px;
}
.Page_Proposal_Details .div_about{
    align-items: center;
    border: 1px solid #111827;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
}
.Page_Proposal_Details .div_img{
    width: 186px;
    height: 100px;
    position: relative;
}
.Page_Proposal_Details .about_img{
    width: 100%;
    height: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.Page_Proposal_Details .about_link{
    position: absolute;
    right: 4px;
    bottom: 4px;
}
.Page_Proposal_Details .icon_link{
    width: 32px;
    height: 32px;
    display: block;
}
.Page_Proposal_Details .about_title{
    position: relative;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    font-size: 12px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    width: -webkit-fill-available;
    background-color: #111827;
}
.Page_Proposal_Details .cont_position{
    color: #A4ABB7;
    font-size: 12px;
}
.Page_Proposal_Details .cont_name{
    color: #606875;
    font-size: 20px;
    font-weight: 500;
}
.Page_Proposal_Details .cont_data{
    color: #606875;
    font-size: 14px;
}
.Page_Proposal_Details .search_proposal{
    background-color: #EEF1F5;
    border: 2px solid #EEF1F5 !important;
}
.Page_Proposal_Details .inf_portfolio_title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.Page_Proposal_Details .color_bg{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}