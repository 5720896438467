.div_show_image{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}
.div_show_image .page_gap {
    gap: 30px;
}
.position_bottom{
    padding-top: 164px;
}
.div_show_image .div_img{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
}
.div_show_image .div_img .div_effect_img{
    width: 100%;
    height: 100%;
    position: absolute;
}
.div_show_image .div_img .type_align_img_left {
    left: 0;
}
.div_show_image .div_img .type_align_img_right{
    right: 0;
}
.div_show_image .div_img .show_img {
    width: 100%;
    height: auto;
}
.div_show_image .div_two_img{
    width: 50%;
}

.div_show_image .div_img_left{
    justify-content: flex-start !important;
}
.div_show_image .div_img_right{
    justify-content: flex-end !important;
}
.div_show_image .div_img .align_img_width{
    width: 75%;
}

@media only screen and (max-width: 850px) {
}
@media only screen and (max-width: 768px) {
    .div_show_image{
        flex-direction: column;
    }
    .div_show_image .position_bottom{
        padding-top: 10px;
    }
    .div_show_image .div_two_img{
        width: 100%;
    }
    .div_show_image .div_img .align_img_width{
        width: 100%;
    }
    .Preview .div_data_contents{
        width: 100% !important;
    }
    .Preview .page_gap{
        gap: 10px;
    }
}
@media only screen and (max-width: 580px) {
    .div_show_image {
        flex-direction: column;
    }
    .PreviewFixed .page_gap{
        gap: 0;
    }
    .position_bottom{
        padding-top: 0px;
    }
}