.div_show_highlight_1{
    color: #ededed;
    width: 90%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.div_show_highlight_1 .highlight_1_title{
    font-size: 72px;
    font-style: normal;
    font-weight: 600;
    line-height: 72px;
    margin-bottom: 40px;
    width: 100%;
}
.div_show_highlight_1 .highlight_1_text {
    width: 100%;
    font-size: 21px;
    line-height: 32px;
    font-weight: 300;
    display: flex;
    justify-content: flex-end;  
    flex-direction: column;  
    align-items: flex-end;
}
.div_show_highlight_1 .highlight_1_text .text{
    width: 70%;
}

@media only screen and (max-width: 850px) {
    .div_show_highlight_1 .highlight_1_title{
        text-align: center;
    }
    .div_show_highlight_1 .highlight_1_text .text{
        width: 100%;
    }
}
@media only screen and (max-width: 580px) {
    .div_show_highlight_1 .highlight_1_title {
        font-size: 54px;
        line-height: 54px;
    }
}