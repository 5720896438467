/* .PopUp {
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .all{
    margin: 0% auto;
} */
.PopUp .show_portfolio .title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    flex-grow: 1;
    padding-bottom: 10px;
    border-bottom: 2px solid #324d6b;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #6c757d;
}
.PopUp .show_portfolio .list_portfolio{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: unset;
}
.PopUp .show_portfolio .list_portfolio .color_bg{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .show_portfolio .list_portfolio .project_data_show_opt{
    color: #ffffff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    z-index: 4;
    font-size: 16px;
}
.PopUp .show_portfolio .list_portfolio .div_project_data:hover .project_data_show_opt{
    opacity: 1;
}
.PopUp .show_portfolio .list_portfolio .div_show_portfolio{
    position: relative;
    width: 238px;
    height: 160px;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
}
.PopUp .show_portfolio .list_portfolio .div_show_portfolio .click_portfolio{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .show_portfolio .list_portfolio .div_show_portfolio .click_portfolio:hover{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(45 60 83 / 80%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
}
.PopUp .show_portfolio .list_portfolio .div_show_portfolio .click_portfolio .icons{
    display: none;
}
.PopUp .show_portfolio .list_portfolio .div_show_portfolio .click_portfolio:hover .icons{
    width: 60px;
    height: 60px;
    display: block;
}
.PopUp .show_portfolio .list_portfolio .div_show_portfolio .title_port{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    color: #ffffff;
    transition: all 0.6s ease;
    background-color: rgb(45 60 83 / 80%);
}
.PopUp .show_portfolio .list_portfolio .div_show_portfolio .img_port{
    width: 100%;
    height: 100%;
}
.PopUp .show_portfolio .list_portfolio .div_show_portfolio .img_port .show_img_port{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}