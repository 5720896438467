.div_show_highlight_2{
    color: #ededed;
    width: 90%;
    margin: 0 auto;
}
.div_show_highlight_2 .highlight_2_title{
    color: hsla(0,0%,100%,.49);
    font-size: 13px;
    letter-spacing: 2px;
    margin-bottom: 14px;
    opacity: .5;
    font-weight: 200; 
    text-align: center; 
}
.div_show_highlight_2 .highlight_2_text{
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 2px;
    margin: 0.67em 0; 
    text-align: center; 
}

.div_show_highlight_2 .align_left{
    text-align: left;
}
.div_show_highlight_2 .align_center{
    text-align: center;
}
.div_show_highlight_2 .align_right{
    text-align: right;
}

@media only screen and (max-width: 992px) {
}
@media only screen and (max-width: 768px) {
}
@media only screen and (max-width: 580px) {
}