.PopUp .new_client{
    width: 540px;
}
.PopUp .new_client .show_input{
    display: flex;
    gap: 10px;
    width: -webkit-fill-available;
}
.PopUp .new_client .div_input{
    width: auto;
}
.PopUp .new_client .space_client{
    width: -webkit-fill-available !important;
}
.PopUp .new_client .space_client_top{
    padding-top: 0;
}
.PopUp .new_client .cnpj{
    width: 140px;
    text-align: center;
}
.PopUp .new_client .list_input_data {
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}