.div_show_gallery_open{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
}

.div_show_gallery_open .div_fixed_img{
    width: 96%;
    position: relative;
}
.div_show_gallery_open .div_fixed_img .div_one{
    display: flex;
    justify-content: center;
}
.div_show_gallery_open .div_fixed_img .div_one:nth-child(n+2) {
    margin-top: -12vw;
}
.div_show_gallery_open .div_fixed_img .div_two{
    display: flex;
    justify-content: space-between;
    margin-top: -15vw;
}

.div_show_gallery_open .div_fixed_img .div_img{    
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    width: 30vw;
    height: 30vw;
}
.div_show_gallery_open .div_fixed_img .div_img .show_img{
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

@media only screen and (max-width: 580px) {
    .div_show_gallery_open .div_fixed_img .div_one{
        flex-direction: column;
        align-items: center;
        margin: 0;
        margin-bottom: 16px;
    }
    .div_show_gallery_open .div_fixed_img .div_one:nth-child(n+2) {
        margin: 0;
        margin-bottom: 16px;
    }
    .div_show_gallery_open .div_fixed_img .div_two{
        flex-direction: column;
        align-items: center;
        margin: 0;
        gap: 16px;
        margin-bottom: 16px;
    }
    .div_show_gallery_open .div_fixed_img .div_img{
        width: calc(100vw - 30px);
        height: 100vw;
    }
}