.Page_Tasks{
    padding-bottom: 40px;
}
.Page_Tasks .div_calendar{
    width: calc(100% - 40px);
    height: auto;
}
.Page_Tasks .div_color_legend{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
}
.Page_Tasks .div_color_legend .color_legend{    
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}
.Page_Tasks .div_color_legend .color_legend .color{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 14px;
    height: 14px;
    border-radius: 14px;
}
.Page_Tasks .div_color_legend .color_legend .text{
    font-size: 12px;
    color: rgb(92, 92, 92);
}
.Page_Tasks .rbc-time-content{
    display: none;
}
.Page_Tasks .rbc-day-bg+ .Page_Tasks .rbc-day-bg,
.Page_Tasks .rbc-month-row{
    min-height: 88px;
    cursor: pointer;
}