.Page_Services_Category {
    width: 100%;
    height: auto;
}
.Page_Services_Category .show_color{
    width: 12px;
    height: 12px;
    border-radius: 50%;
}
.Page_Services_Category .img_service{
    width: auto;
    height: 36px;
    cursor: pointer;
}