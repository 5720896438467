.PopUp .alt_status_tasks{
    width: 600px;
    margin-bottom: 40px;
}
.PopUp .alt_status_tasks .list_input_data {
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.PopUp .alt_status_tasks .div_input{
    width: auto;
    justify-content: flex-start;
}
.PopUp .alt_status_tasks .priority{
    width: 110px;
}
.PopUp .alt_status_tasks .status{
    width: auto;
}
.PopUp .alt_status_tasks .button_btn{
    width: 100%;
    border: none;
    font-size: 15px;
    padding: 10px;
}
.PopUp .alt_status_tasks .show_data_text{
    width: calc(100% - 24px);
    background-color: #fff;
    border: 2px solid #ebebeb;
    border-radius: 6px;
    padding: 10px;
    flex-direction: column;
}
.PopUp .alt_status_tasks .space_column{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    text-align: left;
}
.PopUp .alt_status_tasks .space_row{
    flex-direction: row;
}
.PopUp .alt_status_tasks .show_data_text .space_column .rescheduled{
    width: auto;
}