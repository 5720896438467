.Page_Portfolio_Details{
    width: 100%;
    height: auto;
}

.Page_Portfolio_Details .div_category_title{
    font-weight: 600;
    color: #606875;
}
.Page_Portfolio_Details .div_category_search{
    margin-top: 10px;
}
.Page_Portfolio_Details .search{
    width: 100%;
}

.Page_Portfolio_Details .search:focus, .search:valid {
    border: 2px solid #cdd1d6;
}
.Page_Portfolio_Details .search{
    background-color: #eef0f5;
    border: 2px solid #cdd1d6;
    border-radius: 6px;
    border-radius: 8px;
    font-family: inter;
    font-size: 16px;
    outline: none;
    padding: 10px 10px 10px 44px;
    width: -webkit-fill-available;
}
.Page_Portfolio_Details .year {
    width: 36px;
    text-align: center;
}
.Page_Portfolio_Details .explication {
    color: #cdd1d6;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 16px;
    width: -webkit-fill-available;
}
.Page_Portfolio_Details label{
    position: relative;
}
.Page_Portfolio_Details .div_page_cover{
    width: -webkit-fill-available;
    position: relative;
    padding: 10px;
}
.Page_Portfolio_Details .div_page_cover_edit{
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: rgb(0 0 0 / 50%);
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}
.Page_Portfolio_Details .close_file_edit{
    display: none;
}
.Page_Portfolio_Details .div_cover{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 2px dashed #cdd1d6;
    border-radius: 6px;
    padding: 20px 0px;
    margin-top: 20px;
    width: -webkit-fill-available;
    max-height: 300px;
}
.Page_Portfolio_Details .div_cover_img{
    padding: 0;
    border: none;
}
.Page_Portfolio_Details .close_file{
    position: absolute;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    opacity: 0;
    cursor: pointer;
}
.Page_Portfolio_Details .show_img_cover{
    width: auto;
    height: 100%;
    border-radius: 14px;
    max-width: 100%;
    max-height: 300px;
}
.Page_Portfolio_Details .div_cover_icons .icons{
    display: flex;
    width: 80px;
    height: 80px;
}
.Page_Portfolio_Details .div_cover_title{
    font-weight: 400;
}
.Page_Portfolio_Details .div_cover_explication{
    font-size: 12px;
    font-weight: 600;
}
.Page_Portfolio_Details .div_cover_color{
    color: rgba(7, 196, 214, 1);
}
.Page_Portfolio_Details .div_cover_desc{
    font-size: 10px;
    font-weight: 400;
}
.Page_Portfolio_Details .div_data_project .add_project{
    height: auto;
    width: -webkit-fill-available;
}
.Page_Portfolio_Details .show_data_min_height{
    min-height: 148px !important;
}

.Page_Portfolio_Details .space_icons{
    margin-top: 16px;
}

.Page_Portfolio_Details .list_type_service:nth-child(n+3) {
    border-top: 2px solid #ebebeb;
    padding-top: 16px;
    margin-top: 24px;
}

.Page_Portfolio_Details .type_arrow{
    width: 25px;
}
.Page_Portfolio_Details .model_gallery{
    width: 180px;
}

.Page_Portfolio_Details .align_icon_text{
    justify-content: space-between;
}
.Page_Portfolio_Details .select_type_data {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Page_Portfolio_Details .font_size_text{
    font-size: 12px;
}
.Page_Portfolio_Details .show_inf_type {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f00000;
    border-radius: 24px;
    text-align: center;
    color: #ededed;
    position: absolute;
    top: -6px;
    right: -6px;
}