.PopUp .popup_profile{
    width: 500px;
}
.PopUp .popup_profile .button_profile{
    width: -webkit-fill-available;
}
.PopUp .popup_profile .align_profile{
    text-align: left;
}
.PopUp .popup_profile .span_name_textarea{
    position: absolute;
    left: 8px;
    top: -3px;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
    background-color: #ffffff;
    padding: 0 10px;
}
.PopUp .popup_profile .pass{
    width: 120px;
}
.PopUp .popup_profile .list_input_data {
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.PopUp .popup_profile .div_input{
    width: auto;
}