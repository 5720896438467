.Page_Report_Portfolio .name_project {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 24px;
    color: #2D3C53;
}
.Page_Report_Portfolio .list_opt_year {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Page_Report_Portfolio .list_opt_year .icon_next {
    transform: rotate(180deg);
}