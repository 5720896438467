.PopUp .popup_status_budget{
    width: 570px;
}
.PopUp .popup_status_budget .select_status{
    text-align: left;
}
.PopUp .popup_status_budget .list_input_data{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.PopUp .popup_status_budget .list_input_data {
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.PopUp .popup_status_budget .register_data{
    width: 100%;
}