.PopUp .popup_tasks{
    width: 800px;
    margin-bottom: 40px;
}
.PopUp .popup_tasks .button_profile{
    width: -webkit-fill-available;
}
.PopUp .popup_tasks .align_profile{
    text-align: left;
}
.PopUp .popup_tasks .span_name_textarea{
    position: absolute;
    left: 8px;
    top: -3px;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
    background-color: #ffffff;
    padding: 0 10px;
}
.PopUp .popup_tasks .pass{
    width: 120px;
}
.PopUp .popup_tasks .button_btn{
    width: 100%;
    border: none;
    font-size: 15px;
    padding: 10px;
}
.PopUp .popup_tasks .priority{
    width: 110px;
}
.PopUp .popup_tasks .list_input_data {
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.PopUp .popup_tasks .div_input{
    width: auto;
}

.PopUp .popup_tasks .add_data{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.PopUp .popup_tasks .add_data .data_status{
    padding: 4px 10px;
    background-color: #7c9f06;
    color: #ffffff;
    border-radius: 6px;
    cursor: pointer;
}