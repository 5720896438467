.PopUp .popup_signature{
    width: 500px;
}
.PopUp .popup_signature .div_img{
    width: 100%;
    height: auto;
    max-height: 160px;
}
.PopUp .popup_signature .show_div_img{
    width: 100%;
    height: auto;
    max-height: 160px;
}
.PopUp .popup_signature .div_input{
    width: auto;
}
.PopUp .popup_signature .list_input_data{
    display: flex;
    flex-direction: row;
    
    gap: 10px;
}
.PopUp .popup_signature .list_input_data {
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.PopUp .popup_signature .button_budget{
    width: -webkit-fill-available;
}