.Page_Proposal .div_qtd_proposal {
    width: -webkit-fill-available;
    text-align: end;
}
.Page_Proposal .list_opt_{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
}
.Page_Proposal .new_budget{
    color: #2D3C53;
    background-color: #FFC400;
    border-radius: 20px;
    padding: 6px 0px;
    cursor: pointer;
    width: 100%;
    text-align: center;
}
.Page_Proposal .show_budget{
    color: #FFFFFF;
    background-color: #49a6ff;
    border-radius: 20px;
    padding: 6px 0px;
    cursor: pointer;
    width: 100%;
    text-align: center;
}
.Page_Proposal .show_portfolio{
    color: #ffffff;
    background-color: #E51D4D;
    border-radius: 20px;
    padding: 6px 0px;
    cursor: pointer;
    width: 100%;
    text-align: center;
}
