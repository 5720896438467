.div_show_highlight_4{
    color: #ededed;
    width: 90%;
    margin: 0 auto;
}
.div_show_highlight_4 .highlight_4_title{
    color: hsla(0,0%,100%,.49);
    font-size: 13px;
    letter-spacing: 2px;
    margin-bottom: 14px;
    opacity: .5;
    font-weight: 200;
}
.div_show_highlight_4 .highlight_4_text_1{
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 2px;
    margin: 0;
}
.div_show_highlight_4 .highlight_4_text_2{
    font-size: 21px;
    font-weight: 200;
    margin-top: 40px;
}
.div_show_highlight_4 .align_left{
    text-align: left;
}
.div_show_highlight_4 .align_center{
    text-align: center;
}
.div_show_highlight_4 .align_right{
    text-align: right;
}


@media only screen and (max-width: 992px) {
}
@media only screen and (max-width: 580px) {
}