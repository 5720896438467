.Page_Services_Combo{

}
.Page_Services_Combo .inf_serv{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 2px;
}
.Page_Services_Combo .inf_serv .code{
    background-color: #E2E8F0;
    padding: 2px 8px;
    border-radius: 20px;    
}