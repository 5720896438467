.PopUp .popup_tag{
    width: 600px;
}
.PopUp .popup_tag .div_input{
    width: auto;
}
.PopUp .popup_tag .space_width{
    flex-grow: 1;
}
.PopUp .popup_tag .button_tag{
    width: -webkit-fill-available;
}
.PopUp .popup_tag .type_tag{
    width: 100px;
}
.PopUp .popup_tag .list_input_data{
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}