

/* gallery */
.div_show_gallery{
    width: 100%;
    height: 760px;
    background-color: #121212;
    position: relative;
    display: flex;
    flex-direction: row;
    z-index: 1;
    overflow: hidden;
    padding: 60px 0px;
}
.div_show_gallery .data_fixed{
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    z-index: 2;
}
.div_show_gallery .data_fixed .div_show_preview{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10vw;
    padding: 0px 20px;
}
.div_show_gallery .data_fixed .div_show_preview .div_name{
    font-size: 12px;
    font-weight: 300;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
}
.div_show_gallery .data_fixed .div_show_preview .div_name .line{
    width: 40px;
    border-top: 1px solid #ffffff;
}
.div_show_gallery .data_fixed .div_show_preview .div_name:hover .line {
    transition: 1s;
    animation: line_model_2 0.5s;
    animation-fill-mode: forwards;
}
@keyframes line_model_2 {
    0%{
        width: 40px;
    }
    100% {
        width: 80px;
    }
}
.div_show_gallery .data_fixed .div_arrow{
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    gap: 16px;
    padding-bottom: 60px;
}
.div_show_gallery .data_fixed .div_arrow .arrow_left, .div_show_gallery .data_fixed .div_arrow .arrow_right{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 20px;
    cursor: pointer;
}
.div_show_gallery .data_fixed .div_arrow .space_arrow_left{
    width: 30px;
    cursor: pointer;
    border-top: 1px solid #ffffff;
    left: -14px;
    top: 0px;
    position: relative;
}
.div_show_gallery .data_fixed .div_arrow .disabled_arrow_left {
    width: 30px;
    cursor: default;
    border-top: 1px solid rgb(255 255 255 / 50%);
    left: -14px;
    top: 0px;
    position: relative;
}
.div_show_gallery .data_fixed .div_arrow .arrow_left:hover .space_arrow_left{
    transition: 1s;
    animation: space_arrow_model_2 0.5s;
    animation-fill-mode: forwards;
}
.div_show_gallery .data_fixed .div_arrow .space_arrow_right{
    width: 30px;
    cursor: pointer;
    border-top: 1px solid #ffffff;
    right: -14px;
    top: 0px;
    position: relative;
}
.div_show_gallery .data_fixed .div_arrow .disabled_arrow_right{
    width: 30px;
    cursor: default;
    border-top: 1px solid rgb(255 255 255 / 50%);
    right: -14px;
    top: 0px;
    position: relative;
}
.div_show_gallery .data_fixed .div_arrow .arrow_right:hover .space_arrow_right{  
    width: 40px;  
    transition: 1s;
    animation: space_arrow_model_2 1s;
    animation-fill-mode: forwards;
}
@keyframes space_arrow_model_2 {
    0%{
        width: 40px;
    }
    100% {
        width: 80px;
    }
}
.div_show_gallery .data_fixed .div_arrow .left{
    transform: rotate(-180deg);
}
.div_show_gallery .data_fixed .div_arrow .right{
    transform: rotate(90deg);
}
.div_show_gallery .data_fixed .div_arrow .hide_arrow{
    animation: hide_arrow 1s;
    animation-fill-mode: forwards;
}
@keyframes hide_arrow {
    0%{
        opacity: 1px;
    }
    100% {
        opacity: 0;
    }
}
.div_show_gallery .div_current_gallery{
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;

    color: #ffffff;
    font-weight: 900;
    font-size: 13px;
}
.div_show_gallery .div_current_gallery .list_page{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: default;
}
.div_show_gallery .div_current_gallery .list_page .number{
    text-align: left;
    transition: 1s;
}
.div_show_gallery .div_current_gallery .list_page .position_active{
    width: 0px;
    transition: 1s;
    border-top: 1px solid #ffffff;
    animation: position_active 1s;
    animation-fill-mode: forwards;
}
@keyframes position_active {
    0%{
        width: 0px;
    }
    100% {
        width: 80px;
    }
}

.div_show_gallery .data_fixed .div_show_portfolio{    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
    cursor: pointer;
}
.div_show_gallery .div_gallery_model_2{
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
}
.div_show_gallery .div_gallery_model_2 .show_gallery_model_2{
    width: 100%;
    height: 100%;
    position: absolute;
    transition: 1s;
}
.div_show_gallery .div_gallery_model_2 .show_gallery_model_2:nth-child(1) {
    transform: translateX(-100%);
    opacity: 1;
}
.div_show_gallery .div_gallery_model_2 .show_gallery_model_2:nth-child(2) {
    transform: translateX(0%);
    opacity: 1;
}
.div_show_gallery .div_gallery_model_2 .show_gallery_model_2:nth-child(n+3) {
    transform: translateX(100%);
    opacity: 0;
}
.div_show_gallery .div_gallery_model_2 .show_gallery_model_2 .number_gallery{
    width: 150px;
    font-size: 250px;
    position: absolute;
    text-align: center;
    z-index: 10;
    left: -75px;
    top: 50%;
    transform: translateY(-50%);
    color: #D1D1D1;
    font-weight: 600;
}
.div_show_gallery .div_gallery_model_2 .show_gallery_model_2 .show_text{
    position: absolute;
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.div_show_gallery .div_gallery_model_2 .show_gallery_model_2 .show_text .data_client {
    color: #D1D1D1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 30%;
    height: 360px;
}
.div_show_gallery .div_gallery_model_2 .show_gallery_model_2 .show_text .data_year {
    color: #D1D1D1;
    font-size: 40px;
    width: 100%;
    text-align: right;
    height: 60px;
}
.div_show_gallery .div_gallery_model_2 .show_gallery_model_2 .show_text .data_year .space_year{
    position: relative;
    right: 20%;
}
.div_show_gallery .div_gallery_model_2 .show_gallery_model_2 .show_text .show_data_project{
    padding: 0px 40px 0px 20%;
    height: calc(100% - 520px);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 100px;
}
.div_show_gallery .div_gallery_model_2 .show_gallery_model_2 .show_text .title{
    font-size: 21px;
    line-height: 60px;
    color: #ffffff;
    font-weight: 600;
    display: flex;
    align-items: flex-end;
}
.div_show_gallery .div_gallery_model_2 .show_gallery_model_2 .show_text .text{
    color: #ffffff;
    font-size: 14px;
    width: 100%;
    display: flex;
    align-items: flex-start;
}
.div_show_gallery .div_gallery_model_2 .show_gallery_model_2 .show_text .div_page{
    width: 100%;
    height: 100px;
}
.div_show_gallery .div_gallery_model_2 .show_gallery_model_2 .background_img{
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}
.div_show_gallery .div_gallery_model_2 .show_gallery_model_2 .background_img .img_bg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}


@media only screen and (max-width: 850px) {
    .div_show_gallery .data_fixed{
        position: absolute;
        width: 100%;
    }
    .div_show_gallery .data_fixed .icons{
        color: #ffffff !important;
    }
    .div_show_gallery .data_fixed .div_show_preview{
        bottom: 6vw;
        top: auto;
    }
    .div_show_gallery .data_fixed .div_arrow{
        height: calc(100% - 12vw);
        left: 0;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 12vw;
        background-color: transparent !important;
    }
    .div_show_gallery .div_gallery_model_2{
        width: 100%;
        height: calc(100% - 40px);
    }
    .div_show_gallery .div_gallery_model_2 .show_gallery_model_2{
        overflow-x: hidden;
    }
    .div_show_gallery .div_gallery_model_2 .show_gallery_model_2 .show_text{
        width: 100%;
        align-items: center;
    }
    .div_show_gallery .div_gallery_model_2 .show_gallery_model_2 .number_gallery{
        display: none;
    }
    .div_show_gallery .div_gallery_model_2 .show_gallery_model_2 .show_text .title{
        left: 0;
        width: calc(100% - 100px);
        justify-content: center;
        padding: 0px 50px;
    }
    .div_show_gallery .div_gallery_model_2 .show_gallery_model_2 .show_text .text{        
        width: calc(100% - 100px);
        justify-content: center;
        padding: 0px 50px;
    }
    .div_show_gallery .div_gallery_model_2 .show_gallery_model_2 .show_text .show_data_project{
        padding: 0;
        height: calc(100% - 10vw);
        align-items: center;
    }
}
@media only screen and (max-width: 580px) {
    .div_show_gallery .data_fixed .div_show_preview{
        bottom: 12vw;
    }
    .div_show_gallery .data_fixed .div_arrow{
        height: calc(100% - 80px);
        padding-bottom: 80px;
    }
    .div_show_gallery .div_gallery_model_2 .show_gallery_model_2 .show_text .data_client{
        width: calc(100% - 40px);
        height: 300px;
        padding: 0px 20px;
        gap: 30px;
        justify-content: space-between;
    }
    .div_show_gallery .div_gallery_model_2 .show_gallery_model_2 .show_text .title{
        height: auto;
        font-size: 32px;
        line-height: 46px;
        padding: 0px 30px;
        width: calc(100% - 60px);
        text-align: left;
    }
    .div_show_gallery .div_gallery_model_2 .show_gallery_model_2 .show_text .text{
        height: auto;
        font-size: 16px;
        line-height: 24px;
        padding: 0px 30px;
        width: calc(100% - 60px);
        text-align: left;
    }
    .div_show_gallery .div_current_gallery{
        right: inherit;
        left: 50%;
        transform: translateX(-50%);
    }
}
@media only screen and (max-width: 580px) {
    .div_show_gallery{
        height: 530px;
    }
    .div_show_gallery .data_fixed .div_show_preview{
        bottom: 16vw;
    }
    .div_show_gallery .div_gallery_model_2{
        height: 100%;
    }
}
/* end */