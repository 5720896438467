.Page_Clients_Details .cnpj {
    width: 140px;
    text-align: center;
}
.Page_Clients_Details .customer_since {
    width: 120px;
    text-align: center;
}
.Page_Clients_Details .phone{
    width: 120px;
}
.Page_Clients_Details .btn_status {
    cursor: default;
    padding: 6px 10px;
    border-radius: 40px;
    text-align: center;
}
.Page_Clients_Details .status_1{
    background-color: #c4c4c6;
}
.Page_Clients_Details .status_2{
    background-color: #0484c1;
}
.Page_Clients_Details .status_3{
    background-color: #037f4b;
}
.Page_Clients_Details .status_4{
    background-color: #7a4bcf;
}
.Page_Clients_Details .status_5{
    background-color: #01c875;
}
.Page_Clients_Details .status_6{
    background-color: #e3445a;
}
.Page_Clients_Details .status_7{
    background-color: #ffca00;
}
.Page_Clients_Details .status_8{
    background-color: #65ccff;
}

.Page_Clients_Details .list_opt_{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
}
.Page_Clients_Details .new_budget{
    color: #2D3C53;
    background-color: #FFC400;
    border-radius: 20px;
    padding: 6px 0px;
    cursor: pointer;
    width: 100%;
    text-align: center;
}
.Page_Clients_Details .show_budget{
    color: #FFFFFF;
    background-color: #49a6ff;
    border-radius: 20px;
    padding: 6px 0px;
    cursor: pointer;
    width: 100%;
    text-align: center;
}