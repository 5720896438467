.PopUp .popup_category{
    width: 510px;
}
.PopUp .popup_category .space_top {
    padding-top: 8px;
    margin-top: 0px;
}
.PopUp .popup_category .space_width{
    flex-grow: 1;
}
.PopUp .popup_category .div_input{
    width: auto;
}
.PopUp .popup_category .button_category{
    width: -webkit-fill-available;
}
.PopUp .popup_category .type_category{
    width: 100px;
}
.PopUp .popup_category .div_type{
    width: auto;
}
.PopUp .popup_category .list_input_data{
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.PopUp .popup_category .list_color{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 16px 100px 0px 100px;
}
.PopUp .popup_category .list_color .show_color {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
}
.PopUp .popup_category .list_color .color_selected{
    width: 28px;
    height: 28px;
    position: relative;
    left: -2px;
    top: -2px;
}