.Page_Services .code{
    background-color: #E2E8F0;
    padding: 2px 8px;
    border-radius: 20px;
}
.Page_Services .list_group_or_category {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 6px;
}
.Page_Services .show_group{
    color: #ffffff;
    padding: 2px 10px;
    border-radius: 20px;
    width: fit-content;
}
.Page_Services .show_category{
    font-style: italic;
    color: #A4ABB7;
}
.Page_Services .show_combo{
    border: 1px solid #E2E8F0;
    width: auto;
    padding: 8px 16px;
    border-radius: 6px;
    margin-bottom: 6px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.Page_Services .show_combo:nth-last-child(-n+1){
    margin-bottom: 0px;
}

.Page_Services .list_input_data{
    align-items: center;
    justify-content: center;
}
.Page_Services .list_input_data .category{
    width: 300px;
}
.Page_Services .list_input_data .new_data_page, .Page_Services .list_input_data .new_block_text{
    padding: 10px 14px;
    text-transform: lowercase;
    font-size: 14px;
}