.PopUp .new_portfolio{
    width: 1010px;
}
.PopUp .new_portfolio .div_input {
    width: auto;
}
.PopUp .new_portfolio .input_search{
    width: 400px;
}
.PopUp .new_portfolio .list_portfolio{
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}
.PopUp .new_portfolio .list_portfolio .div_portfolio{
    width: 150px;
    height: 90px;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    position: relative;
}
.PopUp .new_portfolio .list_portfolio .div_portfolio .Tooltip{
    z-index: 10;
}
.PopUp .new_portfolio .list_portfolio .div_portfolio .div_click{
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.PopUp .new_portfolio .list_portfolio .div_portfolio .div_click .show_portfolio{
    height: 24px;
    padding: 4px;
    background-color: #ffffff;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.PopUp .new_portfolio .list_portfolio .div_portfolio .div_click .add_portfolio{
    height: 24px;
    padding: 4px;
    background-color: #ffffff;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .new_portfolio .list_portfolio .div_portfolio .div_title{
    position: absolute;
    bottom: 0;
    width: calc(100% - 8px);
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    line-height: 10px;
    background-color: #2D3C53;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #ffffff;
    padding: 6px 4px;
    cursor: default;
    z-index: 2;
}
.PopUp .new_portfolio .list_portfolio .div_portfolio .div_restricted{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: rgb(0 0 0 / 40%);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    opacity: 1;
    transition: all 2s ease;
    z-index: 1;
}
.PopUp .new_portfolio .list_portfolio .div_portfolio .div_restricted .lock{
    width: 50px;
    height: 50px;
    display: block;
    padding-top: 10px;
}
.PopUp .new_portfolio .list_portfolio .div_portfolio .div_bg{
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}
.PopUp .new_portfolio .list_portfolio .div_portfolio .div_bg .color_bg{
    padding-top: 32px
}
.PopUp .new_portfolio .list_portfolio .div_portfolio .div_bg .portfolio_img{
    position: absolute;
    width: 102%;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    border-radius: 8px;
}
.PopUp .new_portfolio .register_data{
    width: 100%;
    border: none;
    font-size: 15px;
}