
.Page_Budget .list_input_data{
    align-items: center;
    justify-content: center;
}
.Page_Budget .list_input_data .year{
    width: 114px;
}
.Page_Budget .list_input_data .month{
    width: 150px;
}
.Page_Budget .list_input_data .budget_status, .Page_Budget .list_input_data .clients{
    min-width: 200px;
}
.Page_Budget .list_input_data .new_data_page, .Page_Budget .list_input_data .new_block_text{
    padding: 10px 14px;
    text-transform: lowercase;
    font-size: 14px;
}

.Page_Budget .div_download{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
}
.Page_Budget .div_download .btn_download{
    background-color: #2d3c53;
    width: max-content;
    padding: 6px 20px;
    border-radius: 6px;
    color: #ffffff;
    cursor: pointer;
}
.Page_Budget .td_name{
    display: flex;
    align-items: center;
}
.Page_Budget .td_opt{
    display: flex;
    flex-direction: row;
    gap: 4px;
}