.Preview_Budget {
    width: 100%;
    height: auto;
    color: #3d3d3d;
    font-family: 'Archivo';
    background-color: #e5e5e5 !important;
    padding: 40px 0px;
    position: relative;
}
.Preview_Budget  .div_line{
    position: fixed;
    transition: 1s;
    top: 0;
}
.Preview_Budget  .div_line .line_{    
    width: 0px;
    height: 100%;
    z-index: 10;
    pointer-events: none;
    position: fixed;
    border-left: 1px solid rgba(77, 77, 77, .3);
}
.Preview_Budget  .div_line .line_0{
    left: 25%;
}
.Preview_Budget  .div_line .line_1{
    left: 50%;
}
.Preview_Budget  .div_line .line_2{
    left: 75%;
}

.Preview_Budget .container{
    width: 80%;
    background-color: #ffffff;
    margin: 0% auto;
    border-radius: 6px;
    position: relative;
    z-index: 2;
    margin-bottom: 40px;
}
.Preview_Budget .container .subtitle_1{
    font-weight: 700;
}
.Preview_Budget .container table{
    padding-bottom: 0;
}
.Preview_Budget .container table td{
    font-size: 14px;
    padding: 6px;
}
.Preview_Budget .container table th{
    padding: 10px;
}
.Preview_Budget .container table tr:nth-child(2n){
    background-color: transparent;
}

/* div_download */
.Preview_Budget .container .div_download{
    position: absolute;
    right: 3px;
    top: 3px;
}
.Preview_Budget .container .div_download .download{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;    
}
.Preview_Budget .container .div_download .download .img_download{
    width: 100%;
    height: auto;
    fill: #ffffff;    
}
/* end */

/* div_topic */
.Preview_Budget .container .div_topic{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 50px 40px 40px;
    border-bottom: 1px solid #3d3d3d;
}
.Preview_Budget .container .div_topic .div_logo .div_height_logo{
    height: 80px;
}
.Preview_Budget .container .div_topic .div_logo .logotipo{
    width: 200px;
    height: auto;
    display: block;
}
.Preview_Budget .container .div_topic .div_logo .div_name{
    margin-top: 0px;
}
.Preview_Budget .container .div_topic .div_data_2{
    text-align: right;
}
.Preview_Budget .container .div_topic .div_data_2 .subtitle_1_name{
    text-align: center;
    font-weight: 700;
}
.Preview_Budget .container .div_topic .div_data_2 .title_1{
    font-size: 40px;
    text-transform: uppercase;
}
/* end */

/* div_data_budget */
.Preview_Budget .container .div_data_budget{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 40px;
    border-bottom: 1px solid #3d3d3d;
}
/* end */

/* div_show_contents */
.Preview_Budget .container .div_show_contents {
    padding: 30px 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.Preview_Budget .container .div_show_contents table {
    margin-bottom: 16px;
}
.Preview_Budget .container .div_show_contents .th_table{
    width: calc(100% - 320px);
}
.Preview_Budget .container .div_show_contents table thead tr td{
    border-radius: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid #3d3d3d !important;
}
.Preview_Budget .container .div_show_contents table th {
    font-weight: 700;
    background-color: #3d3d3d;
    color: #ffffff;
    border-radius: 0;
}
.Preview_Budget .container .div_show_contents table .th_mobile{
    background-color: #adadadfc;
}
.Preview_Budget .container .div_show_contents table .border_mobile_left{
    background-color: #adadadfc;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.Preview_Budget .container .div_show_contents table .border_mobile_right{
    background-color: #adadadfc;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.Preview_Budget .container .div_show_contents table td{
    border-left: 1px solid #3d3d3d;
    border-bottom: 1px solid #3d3d3d;
}
.Preview_Budget .container .div_show_contents table .td_right{
    border-right: 1px solid #3d3d3d;
}
.Preview_Budget .container .div_show_contents table .service_title{
    font-weight: 600;
    font-style: 16px;
}
.Preview_Budget .container .div_show_contents table .service_text{
    font-size: 14px;
    word-break: break-word;
}
/* end */

/* div_text_fixed */
.Preview_Budget .container .div_text_fixed {
    padding: 10px 40px 40px;
}
/* end */

/* div_signature */
.Preview_Budget .container .div_signature{
    padding: 10px 40px 30px;
}
.Preview_Budget .container .div_signature .show_signature .div_img .img{
    width: auto;
    height: 70px;
}
/* end */

.Preview_Budget .container .show_budget_desktop{
    transition: 1s;
}
.Preview_Budget .container .show_budget_mobile{
    transition: 1s;
    display: none;
}

@media only screen and (max-width: 1002px) {
    .Preview_Budget .container .div_topic .div_logo{
        margin-bottom: 10px;
    }
}
@media only screen and (max-width: 750px) {
    .Preview_Budget .container{
        width: 90%;
    }
    .Preview_Budget .container .show_budget_desktop{
        display: none;
    }
    .Preview_Budget .container .show_budget_mobile{
        display: contents;
    }
    .Preview_Budget .container .div_data_budget{
        flex-direction: column;
        align-items: flex-start;
    }
    .Preview_Budget .container .subtitle_1{
        text-align: left;
    }
    .Preview_Budget .container .div_show_contents .td_service .service_text{
        margin-bottom: 20px;
    }
}
@media only screen and (max-width: 650px) {
    .Preview_Budget .container .div_topic{
        flex-direction: column;
        gap: 10px;
        padding: 50px 20px 20px;
    }
    .Preview_Budget .container .div_topic .div_data_2 .subtitle_1_name{
        text-align: left;
    }
    .Preview_Budget .container .div_data_budget, .Preview_Budget .container .div_show_contents{
        padding: 20px;
    }
    .Preview_Budget .container .div_topic .div_logo .div_name{
        margin-top: 10px;
    }
    .Preview_Budget .container .div_topic .div_data_2 .div_height_title{
    }
}