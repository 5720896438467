.Page_Service_Details .show_color_ {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
}
.Page_Service_Details .div_group{
    justify-content: space-between;
}
.Page_Service_Details .tag{
    width: 80px;
    text-align: center;
}
.Page_Service_Details .div_show_img{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-top: 20px;
}
.Page_Service_Details .div_img{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 2px dashed #cdd1d6;
    border-radius: 6px;
    width: -webkit-fill-available;
    max-height: 300px;
    position: relative;
}
.Page_Service_Details label{
    cursor: pointer;
    padding: 20px 0px;
}
.Page_Service_Details .show_div_img{
    position: relative;
}
.Page_Service_Details .close_file{
    position: absolute;
    left: 0;
    top: 0;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    opacity: 0;
    cursor: pointer;
}
.Page_Service_Details .close_file_edit{
    display: none;
}
.Page_Service_Details .show_img{
    display: flex;
    align-items: center;
    justify-content: center;
}
.Page_Service_Details .img_width{
    width: 100%;
    height: 100%;
}
.Page_Service_Details .img_color{
    padding: 6px;
    border-radius: 6px;
    background-color: #2c2c2c;
}
/* 
.Page_Service_Details .div_icons .icons{
    display: flex;
    width: 80px;
    height: 80px;
} 
*/
.Page_Service_Details .div_alt_img{
    width: -webkit-fill-available;
    position: relative;
}
.Page_Service_Details .div_page_cover_edit{
    position: absolute;
    right: 0;
    z-index: 30;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}
.Page_Service_Details .div_title{
    color: #606875;
    font-weight: 500;
    font-size: 14px;
    margin-top: 20px;
    text-align: center;
}
.Page_Service_Details .div_subtitle{
    color: #606875;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
}
.Page_Service_Details .subtitle_desc{
    color: #07C4D6;
}
.Page_Service_Details .space_top{
    margin-top: 20px;
}
.Page_Service_Details .space_icons{
    margin-top: 26px;
}
.Page_Service_Details .list_data_report{
    width: 100%;
}
.Page_Service_Details .cash{
    width: 110px;
}

.Page_Service_Details .show_data_project{
    height: auto;
}

.Page_Service_Details .search {
    background-color: #eef0f5;
    border: 2px solid #cdd1d6;
    border-radius: 6px;
    border-radius: 20px;
    font-family: inter;
    font-size: 16px;
    outline: none;
    padding: 10px 10px 10px 44px;
    width: -webkit-fill-available;
}

.Page_Service_Details .add_project {
    height: auto;
    width: -webkit-fill-available;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
}
.Page_Service_Details .add_project .div_opt{
    width: 80px;
    height: 80px;
    cursor: pointer;
}
.Page_Service_Details .add_project .div_opt .img_opt{
    width: 100%;
    height: auto;
    display: flex;
}
.Page_Service_Details .div_data_contents{
    width: 100%;
}
.Page_Service_Details .div_data_contents .two_project{
    display: flex;
    flex-direction: row;
    gap: 16px;
}
.Page_Service_Details .div_data_contents .return_circle{
    margin-bottom: 20px;
}
.Page_Service_Details .div_data_contents .add_project{
    justify-content: flex-start;
    gap: 0;
}
.Page_Service_Details .div_data_contents .add_project .list_input_data{
    width: 100%;
}

.Page_Service_Details .list_name_page {
    display: flex;
    gap: 8px;
}
.Page_Service_Details .list_name_page .div_name_page_access {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border: 1px solid #2d3c53;
    padding: 4px 10px;
    border-radius: 6px;
}