.PopUp .popup_newcombo {
    width: 600px;
}
.PopUp .title_group{
    font-weight: 600;
    text-align: left;
    width: -webkit-fill-available;
}
.PopUp .show_color{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
}
.PopUp .color_selected{
    width: 28px;
    height: 28px;
    position: relative;
    left: -2px;
    top: -2px;
}
.PopUp .div_save{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #E51D4D;
    font-weight: 600;
    margin-top: 20px;
    width: -webkit-fill-available;
}
.PopUp .btn_{
    width: 120px;
    cursor: pointer;
}
.PopUp .button_save{
    color: #ffffff;
    background-color: #E51D4D;
    border-radius: 6px;
    padding: 6px 0px;
}
.PopUp .add_new_serv{
    cursor: pointer;
    color: #E51D4D;
    font-weight: 600;
    width: 100px !important;
}

.PopUp .popup_newcombo .list_input_data{
    display: flex;
    justify-content: flex-start;
    width: -webkit-fill-available;
    align-items: center;
    gap: 10px;
}
.PopUp .popup_newcombo .div_input{
    width: auto !important;
}
.PopUp .popup_newcombo .div_show_select_height{
    margin-top: '2px';
    width: -webkit-fill-available
}
.PopUp .popup_newcombo .div_show_select_list_data{
    margin-top: 0px;
    top: 46px;
}