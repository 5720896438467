.Page_Portfolio{
    width: 100%;
    height: auto;
}
.Page_Portfolio .list_portfolio_new_proposal{
    flex-wrap: nowrap;
}
.Page_Portfolio .click_open{
    flex-grow: 1;
    width: 100%;
    height: 60px;
}

/* create new proposal */
.Page_Portfolio .new_proposal{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 16px;
    background: rgba(7, 196, 214, 0.1);
    border-radius: 4px;
    width: 244px;
    min-width: 244px;
}
.Page_Portfolio .proposal_title{
    font-family: 'Inter';
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #07C4D6;
}
.Page_Portfolio .save_proposal{
    text-align: left;
    width: -webkit-fill-available;
}
.Page_Portfolio .summary {
    font-family: 'Inter';
    font-weight: 600;
    color: #07C4D6;
    border-bottom: 2px solid #07C4D6;
    text-align: left;
    padding-bottom: 6px;
}
.Page_Portfolio .list_project_add {
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
}
.Page_Portfolio .show_icon_proposal{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 6px;
    background-color: #FFFFFF;
    border-radius: 6px;
    border: 2px solid #ebebeb;
}
.Page_Portfolio .cancel_proposal{
    font-family: 'Inter';
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: #E51D4D;
    cursor: pointer;
    text-align: center;
}
.Page_Portfolio .input_new_proposal{
    resize: none;
}
/* end */