
.Page_Users_Details .data_select{
    width: 140px;
}
.Page_Users_Details .pass, .Page_Users_Details .dash_user_name {
    width: 120px;
}
.Page_Users_Details .dash_user_email {
    width: 220px;
}
.Page_Users_Details .list_name_page{
    display: flex;
    gap: 8px;
}
.Page_Users_Details .div_name_page_access{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border: 1px solid #2d3c53;
    padding: 4px 10px;
    border-radius: 6px;
}

.Page_Users_Details .table_td{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 10px;
    border: 1px solid #2d3c53;
    flex-grow: 1;
}
.Page_Users_Details .td_active{
    color: #ffffff;
    background-color: #008562;
    border: 1px solid #008562;
}