.Page_Report {
    width: 100%;
    height: 100%;
}
.Page_Report .name_project {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 24px;
    color: #2D3C53;
}
.Page_Report .list_current_data{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 40px;
}
.Page_Report .list_current_data .show_qtd{
    width: 200px;
    height: 110px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
}
.Page_Report .list_current_data .show_qtd:hover{
    transform: scale(0.98);
}
.Page_Report .list_current_data .show_qtd .number{
    font-size: 40px;
    font-weight: 700;
    color: #2D3C53;
    border: 1px solid #2D3C53;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.Page_Report .list_current_data .show_qtd .title{
    font-size: 14px;
    padding: 10px;
    color: #ffffff;
    background-color: #2D3C53;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}