.PopUp .details_tasks{
    width: 800px;
    margin-bottom: 40px;
}
.PopUp .details_tasks .list_input_data {
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.PopUp .details_tasks .div_input{
    width: auto;
    justify-content: flex-start;
}
.PopUp .details_tasks .priority{
    width: 110px;
}
.PopUp .details_tasks .status{
    width: auto;
}
.PopUp .details_tasks .button_btn{
    width: 100%;
    border: none;
    font-size: 15px;
    padding: 10px;
}
.PopUp .details_tasks .show_data_text{
    width: calc(100% - 24px);
    background-color: #fff;
    border: 2px solid #ebebeb;
    border-radius: 6px;
    padding: 10px;
    flex-direction: column;
}
.PopUp .details_tasks .list_responsible{
    margin-top: 10px;
    border-bottom: 1px solid #324d6b;
    width: 100%;
    padding-bottom: 10px;
}
.PopUp .details_tasks .disabled{
    color: #9b9b9b;
    cursor: default;
}
.PopUp .details_tasks .space_responsible{
    flex-wrap: wrap;
}
.PopUp .details_tasks .space_responsible input{
    width: 220px;
}
.PopUp .details_tasks .space_column{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    text-align: left;
}
.PopUp .details_tasks .space_row{
    flex-direction: row;
}
.PopUp .details_tasks .show_data_text .space_column .rescheduled{
    width: auto;
}
.PopUp .details_tasks .space_line{
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 10px;
    width: 100%;
}
.PopUp .details_tasks .space_line:nth-last-child(-n + 1){
    border-bottom: none;
    padding-bottom: 0;
}