.Page_Report_Budget .name_project {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 24px;
    color: #2D3C53;
}
.Page_Report_Budget .list_opt_year{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Page_Report_Budget .list_data_report{
    overflow-x: hidden;
}
.Page_Report_Budget .list_table{
    border-radius: 10px;
    cursor: grab;
    overflow-x: hidden;
    border: 1px solid #E2E8F0;
}
.Page_Report_Budget .end_th_left{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 8px;
}
.Page_Report_Budget .end_th_right{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 8px;
}
.Page_Report_Budget .list_table table{
    padding-bottom: 0;
}
.Page_Report_Budget .show_title{
    margin-bottom: 10px;
    font-weight: 600;
    text-transform: uppercase;
}
.Page_Report_Budget .icon_next {
    transform: rotate(180deg);
}
.Page_Report_Budget .list_week{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 2px;
    border-top: 1px solid #a3a3a3;
    height: 26px;
}
.Page_Report_Budget .week{
    width: 50px;  
}
.Page_Report_Budget .week_active{
    height: 26px;
    background-color: #e9e261;
    border-radius: 8px;
}
.Page_Report_Budget .week_checked{
    height: 26px;
    background-color: #61e9ae;
    border-radius: 8px;
}
.Page_Report_Budget .td{
    border-left: 1px solid #a3a3a3;
    border-radius: 0px;
}
.Page_Report_Budget .td:nth-child(-n+1){
    border-left: 0;
}
.Page_Report_Budget .list_data_year{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0px;
}
.Page_Report_Budget .div_cash_total{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    width: -webkit-fill-available;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #2d3c53;
}
.Page_Report_Budget .div_cash_total:nth-child(-n + 1){
    margin-top: 10px;
}
.Page_Report_Budget .div_cash_total:nth-last-child(-n + 1){
    margin-bottom: 0;
}
.Page_Report_Budget .title_cash{
    font-size: 12px;
    color: #6c757d;
}

.Page_Report_Budget .opt_download{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    /* margin-bottom: 10px; */
}
.Page_Report_Budget .opt_download .title_opt{
    padding: 6px 0px;
}
.Page_Report_Budget .opt_download .show_opt{
    padding: 6px 10px;
    background-color: #2d3c53;
    color: #ffffff;
    border-radius: 6px;
    cursor: pointer;
}

.Page_Report_Budget .list_table .width_name_project{
    width: 260px;
    min-width: 260px;
    max-width: 260px;
}
.Page_Report_Budget .list_table .width_name_data{
    width: 70px;
    min-width: 70px;
    max-width: 70px;
}
.Page_Report_Budget .list_table .width_name_dono{
    width: 110px;
    min-width: 110px;
    max-width: 110px;
}
.Page_Report_Budget .list_table .width_name_orc{
    width: 90px;
    min-width: 90px;
    max-width: 90px;
    border-top-right-radius: 0px;
    padding-right: 0px;
}

.Page_Report_Budget .list_data_report .list_month_week{
    display: flex;
    flex-direction: row;
}
.Page_Report_Budget .list_data_report .list_month_week .data_table_fixed{
    width: 660px;
    min-width: 660px;
    max-width: 660px;
}
.Page_Report_Budget .list_data_report .list_month_week .data_table_month{
    flex-grow: 1;
    overflow-x: hidden;
}
.Page_Report_Budget .list_data_report .list_month_week .data_table_month .height_td{
    height: 50px;
}