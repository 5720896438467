.Toast{
    position: fixed;
    right: 20px;
    bottom: 20px;
    color: #ededed;
    background-color: rgb(45 60 83 / 86%);
    border-radius: 8px;
    box-shadow: 0px 0px 18px rgb(0 0 0 / 30%);
    z-index: 999;
    transition: 1s;
    animation: Toast 0.3s linear forwards;
    opacity: 0;
}
@keyframes Toast {
    100%{
        opacity: 1;
    }
}
.Toast .div_text{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 22px;
    padding: 10px 0px;
}
.Toast .div_text .text_toast{
    padding:0px 30px;
}
.Toast .div_icon_close{
    padding-right: 10px;
}
.Toast .progress{
    width: 0%;
    height: 4px;
    border-radius: 8px;
    background-color: #FFC400;
    animation: progress 1.5s linear forwards;
}
@keyframes progress {
    100%{
        width: 100%;
    }
}