.PopUp .SaveBudget{
    width: 520px;
}
.PopUp .SaveBudget .title{
    justify-content: center;
}
.PopUp .SaveBudget .div_save_budget{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.PopUp .SaveBudget .opt_select{
    width: 200px;
    cursor: pointer;
    border-radius: 6px;
    padding: 14px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 4px;
    font-size: 12px;
    border: 1px solid #2D3C53;
    color: #2D3C53;
}
.PopUp .SaveBudget .opt_select:hover, .PopUp .SaveBudget .active_btn{
    background-color: #2D3C53;
    color: #ffffff;
}
.PopUp .SaveBudget .register_data{
    border: transparent;
    padding: 10px;
    font-size: 14px;
}
.PopUp .SaveBudget .space_client{
    width: 100%;
}