.PopUPColor{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 8000;
    position: fixed;
    margin: 0% auto;
    overflow-y: auto;
    background: #FFF;
    overflow-x: hidden;
    background-color: rgba(0, 0, 0, 50%);
    font-family: 'Inter';
}
.PopUPColor .div_all{    
    width: 300px;
    height: auto;
    display: flex;
    text-align: center;
    margin: 2em auto 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    overflow-y: hidden;
    position: relative;
    border-radius: 6px;
    z-index: 30;
}
.InputColor{ 
    width: 240px;
    padding: 30px;
    overflow: hidden;
}
.InputColor .div_saturation{    
    width: 240px;
    height: 146px;
    position: relative;
    margin-bottom: 20px;
}
.InputColor .w-color-saturation{
    width: 240px !important;
    height: 146px !important;
    border-radius: 8px !important;
}
.InputColor .div_hue{
    position: relative;
    margin-bottom: 16px;
}
.InputColor .hue-picker{
    width: 240px !important;
}
.InputColor .hue-horizontal{
    border-radius: 10px !important;
}
.InputColor .div_input{    
    position: relative;
    margin-bottom: 10px;
}
.InputColor .color_select{
    width: 32px;
    height: 32px;
    border-radius: 4px;
    position: absolute;
    top: 6px;
    left: 202px;
}
.InputColor .color_hex{
    display: flex;
    align-items: center;
}
.InputColor .color_rgb{
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.InputColor .color_rgb_div{
    height: 16px;   
    width: -webkit-fill-available;
    margin-top: 4px;
}
.InputColor .input_rgb{
    width: 30px;
    height: 30px;
    padding: 0px 6px
}
.InputColor .svg_icon{
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.InputColor .div_select_type{
    text-align: left;
    display: flex;
    gap: 20px;
}
.InputColor .type{
    font-weight: 600;
    font-size: 16px;
    color: #2D3C53;
}
.InputColor .div_button{
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
}
.InputColor .cancel{
    cursor: pointer;
    color: #E51D4D;
}
.InputColor .add_color{
    cursor: pointer;
    color: #FFFFFF;
    padding: 8px 16px;
    background: #E51D4D;
    border-radius: 8px;
}