
.div_show_text_simple{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: #ededed;
    padding-right: 15px;
}
.div_show_text_simple .text{
    font-size: 16px;
    line-height: 26px;
    font-family: 'inter';
    text-align: left;
}
.div_show_text_simple .align_height_top{
    justify-content: flex-start;
}
.div_show_text_simple .align_height_center{
    justify-content: center;
}
.div_show_text_simple .align_height_bottom{
    justify-content: flex-end;
}
.div_show_text_simple .align_width_left{    
    text-align: left;
}
.div_show_text_simple .align_width_center{
    text-align: center;
}
.div_show_text_simple .align_width_right{
    text-align: right;
}
@media only screen and (max-width: 850px) {
    .div_show_text_simple {
        width: 90%;
        margin: 0 auto;
    }
}